import clsx from 'clsx';
import styles from './number_statistic.module.css';
import { useContext } from 'react';
import LanguageContext from 'language-context';
import Loading from 'components/loading';
import { Link } from 'react-router-dom';
import Icon from 'components/icon';
import TooltipComponent from 'components/tooltip';

export default function NumberStatistic({
  parameter,
  number,
  percentageChange,
  onClick,
}) {
  const tc = useContext(LanguageContext);
  const positiveChange = percentageChange >= 0;
  const translate = (string) => {
    switch (string) {
      case 'wonDeals':
        return tc.wonPlural;
      case 'lostDeals':
        return tc.lostPlural;
      case 'activityCount':
        return tc.activities;
      default: {
        return 'Text saknas';
      }
    }
  };
  const clickable = ['activityCount'].includes(parameter);
  const link = ['wonDeals', 'lostDeals'].includes(parameter);
  function getLink(param) {
    switch (param) {
      case 'wonDeals':
        return '/bearbeta/affarer/vunna';
      case 'lostDeals':
        return '/bearbeta/affarer/forlorade';
      default:
        return '/';
    }
  }
  const children = (
    <>
      {number === null && <Loading small />}
      {number !== null && (
        <>
          <div>
            <p>{translate(parameter)}</p>

            {percentageChange !== null && (
              <TooltipComponent
                tooltipContent={
                  'Förändring jämfört med samma period förra månaden'
                }
              >
                <p
                  className={clsx(
                    positiveChange
                      ? styles.positiveChange
                      : styles.negativeChange,
                    styles.change
                  )}
                >
                  {positiveChange ? '+' : ''}
                  {percentageChange}%
                  <span className={styles.changeIcon}>
                    {positiveChange ? '▲' : '▼'}
                  </span>
                </p>
              </TooltipComponent>
            )}
          </div>
          <div className={styles.cardBottom}>
            <p className={styles.number}>{number}</p>
          </div>
          {link && <Icon val="northEast" class={styles.linkIcon} />}
          {!link && clickable && (
            <Icon val="openInBrowser" class={styles.linkIcon} />
          )}
        </>
      )}
    </>
  );
  return link ? (
    <Link
      to={getLink(parameter)}
      className={clsx(
        styles.card,
        styles[parameter],
        link && styles.link,
        styles.clickable
      )}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={() => {
        if (!clickable) return;
        if (!number) return;
        if (clickable) onClick(parameter);
      }}
      className={clsx(
        styles.card,
        clickable && number && styles.clickable,
        styles[parameter]
      )}
    >
      {children}
    </div>
  );
}
