import React, { useContext } from 'react';
import LanguageContext from 'language-context';
import { textHelper } from 'helpers';

/**
 * /**
 * Render preview row.
 *
 * @param props.name - string
 * @param props.obj - object - Data object.
 */
const PreviewSectionRow = (props) => {
  const tc = useContext(LanguageContext);

  /**
   * Build values.
   */
  const buildValues = (payload, level = 0) => {
    if (Array.isArray(payload)) {
      return payload.map((num) => {
        let rows = [];
        let content = (
          <>
            {/*<span>{textHelper.formatText(num.label)}</span>*/}
            <span>{num.label}</span>
            {num.hasOwnProperty('from') && num.hasOwnProperty('to') ? (
              <div>
                {tc.from}: {num.from} {tc.to.toLowerCase()} {num.to}{' '}
                {num.unit.toLowerCase()}
              </div>
            ) : null}
          </>
        );

        rows.push(
          <div className={'level__' + level} key={num.label + level}>
            {content}
          </div>
        );

        if (Array.isArray(num.values)) {
          rows = rows.concat(buildValues(num.values, level + 1));
        }

        return rows;
      });
    } else if (payload.hasOwnProperty('from') && payload.hasOwnProperty('to')) {
      return (
        <div className={'level__' + level} key={payload.label + level}>
          <span>
            {tc.from} {payload.from} {tc.to.toLowerCase()} {payload.to}{' '}
            {payload.selected
              ? tc[payload.selected]?.toLowerCase()
              : payload.unit
                ? payload.unit.toLowerCase()
                : null}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="previewSectionRow">
      <div className="previewSectionRow__left">
        {/*{tc[props.name]*/}
        {/*  ? textHelper.formatText(tc[props.name])*/}
        {/*  : textHelper.formatText(props.name)}*/}
        {tc[props.name] ? tc[props.name] : props.name}
      </div>
      <div className="previewSectionRow__right">
        {buildValues(props.obj[props.name], 0)}
      </div>
    </div>
  );
};

export default PreviewSectionRow;
