import { store } from 'store';
import { request, requestBody } from 'helpers';
import { listsActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import * as text from 'text-content';
import listHelper from 'shared_helpers/list_helper';

/**
 * Archive a list of list ids.
 *
 * @param payload.listIds - array
 */
export const archiveLists = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length)
    ) {
      return console.error('Missing params in archiveLists', payload);
    }

    const data = await request({
      data: {
        listIds: payload.listIds,
      },
      method: 'post',
      url: '/lists/archive/',
    });

    if (data instanceof Error) {
      return console.error('Error in archiveLists', data);
    }

    showFlashMessage(tc.listsHaveBeenArchived, 'success');

    // Filter lists from state directly to prevent new backend call.
    const newLists = store
      .getState()
      .lists.lists.filter((list) => !payload.listIds.includes(list.id));
    return store.dispatch({
      type: listsActionTypes.LISTS_SET_LISTS,
      payload: newLists,
    });
  } catch (err) {
    return console.error('Error in archiveLists', err);
  }
};

/**
 * Create list subscription.
 *
 * @param payload.mailings - object
 * @param payload.subscribeFlag - number
 * @param payload.listIds - string
 */
export const createListSubscription = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length) ||
      (payload && !payload.hasOwnProperty('subscribeFlag'))
    ) {
      console.error('Missing params in createListSubscription', payload);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    if (
      payload.subscribeFlag & listHelper.subscriptionFlags.mailings &&
      (!payload.mailings ||
        !payload?.mailings?.hasOwnProperty('color') ||
        !payload?.mailings?.hasOwnProperty('postage') ||
        !payload?.mailings?.hasOwnProperty('pdfId') ||
        !payload?.mailings?.hasOwnProperty('simplexDuplex') ||
        !payload?.mailings?.hasOwnProperty('Typ') ||
        !payload?.mailings?.hasOwnProperty('ADR') ||
        !payload?.mailings?.hasOwnProperty('mailingsType'))
    ) {
      console.error(
        'Missing mailings params in createListSubscription',
        payload
      );
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    const data = await requestBody({
      data: {
        listIds: payload.listIds,
        mailings: payload.mailings,
        subscribeFlag: payload.subscribeFlag,
        options: payload.options,
      },
      method: 'post',
      url: '/lists/subscriptions/subscribe/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.genericFailMessage, 'fail');
      return console.error('Error in createListSubscription', data);
    }

    showFlashMessage(tc.listsSubscriptionsHaveBeenCreated, 'success');
    getLists({});
    getListsSubscriptions();
    return;
  } catch (err) {
    showFlashMessage(tc.genericFailMessage, 'fail');
    return console.error('Error in createListSubscription', err);
  }
};

export const editListSubsciption = async (payload: {
  id: number;
  mailings: {
    color: boolean;
    postage: 'A' | 'B';
    simplexDuplex: 'S' | 'D';
    type: 'Brev' | 'Vykort';
    mailingsType: 'car' | 'user';
    pdfId: number;
    s3Key: string;
    ADR: string;
  };
  prefixName: string;
  subscribeFlag: number;
  options: {
    interval: any;
    limitProspects: any;
    excludeProspects: any;
    toggleTimelimitON: boolean;
    toggleTimelimitOFF: boolean;
  };
}) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const data = await requestBody({
      data: payload,
      method: 'post',
      url: '/lists/subscriptions/edit/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.genericFailMessage, 'fail');
      return console.error('Error in editListSubscription', data);
    }

    showFlashMessage(tc.listsSubscriptionsHaveBeenCreated, 'success');
    getLists({});
    getListsSubscriptions();
    return;
  } catch (err) {
    showFlashMessage(tc.genericFailMessage, 'fail');
    return console.error('Error in editListSubscription', err);
  }
};

/**
 * Get lists.
 *
 * @param payload.archived - bool (optional) - If set to true, get archived lists.
 * @param payload.orderInformation - bool (optional) - If set to true, get order history for lists. Sometimes we only want to retrieve lists to use in a dropdown and we dont need order history.
 */
export const getLists = async (payload) => {
  try {
    const from = new Date(Date.now());
    const to = new Date(Date.now());
    from.setMonth(from.getMonth() - 1);

    const data = await request({
      data: {
        archived: payload && payload.archived ? payload.archived : false,
        dateRange: payload.dateRange,
        subscriptionLists:
          payload && payload.subscriptionLists
            ? payload.subscriptionLists
            : false,
      },
      method: 'get',
      url: '/lists/',
    });

    if (
      !data ||
      (data && !data.data) ||
      (data && data.data && !data.data.length)
    ) {
      if (payload && payload.archived) {
        return store.dispatch({
          type: listsActionTypes.LISTS_SET_LISTS_ARCHIVED,
          payload: [],
        });
      } else if (payload && payload.subscriptionLists) {
        store.dispatch({
          type: listsActionTypes.LISTS_SET_LISTS_SUBSCRIPTIONLISTS,
          payload: [],
        });
      } else {
        return store.dispatch({
          type: listsActionTypes.LISTS_SET_LISTS,
          payload: [],
        });
      }
    }

    if (data instanceof Error) {
      return console.error('Error in getLists', data);
    }

    // First, set lists so components can start to render.
    let lists = data.data;
    if (payload && payload.archived) {
      store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS_ARCHIVED,
        payload: lists,
      });
    } else if (payload && payload.subscriptionLists) {
      store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS_SUBSCRIPTIONLISTS,
        payload: lists,
      });
    } else {
      store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS,
        payload: lists,
      });
    }

    if (payload.orderInformation) {
      // Second, get amount of private persons, companies and nomarketing for each list.
      // const prospectInformation = await getListsProspectInformation({
      //   listIds: lists.map((num) => num._id),
      // });
      // lists = lists.map((list) => {
      //   let obj = prospectInformation.find((x) => x.id === list.id);
      //   if (obj) {
      //     list.prospectInformation = obj.data;
      //   }
      //   return list;
      // });

      // if (!lists?.length) {
      //   return;
      // }

      /*
      Third, retrieve order information for each list.
      (Note that lists have static properties 'orderCompany', 'orderMailings' etc, but we do not rely on these for order information.
      These properties have historically been set every time a user places an order, but that doesn't show if order is canceled or delivered.
      Here we do an actual check in databases for order information instead.)
      */
      const orderInformation = await request({
        data: {
          listIds: lists.map((num) => num.id),
        },
        method: 'post',
        url: '/lists/getOrderHistory/',
      });

      if (Array.isArray(orderInformation)) {
        lists.map((list) => {
          const orderDataObj = orderInformation.filter(
            (num) => num.id === list.id
          );
          list.availableOrderData = orderDataObj[0].availableOrderData;
          list.orderHistory = orderDataObj[0].orderHistory;
          return list;
        });
      }

      // Update lists in state with added information.
      if (payload && payload.archived) {
        return store.dispatch({
          type: listsActionTypes.LISTS_SET_LISTS_ARCHIVED,
          payload: lists,
        });
      } else {
        return store.dispatch({
          type: listsActionTypes.LISTS_SET_LISTS,
          payload: lists,
        });
      }
    }
  } catch (err) {
    return console.error('Error in getLists', err);
  }
};

/**
 * Get amount of private persons, companies and nomarketing for lists.
 *
 * @param payload.orderMailings.listIds
 */
export const getListsProspectInformation = async (payload) => {
  try {
    if (!payload || (payload && !payload.listIds)) {
      console.error('Missing data in getListsProspectInformation');
      return [];
    }
    const res = await request({
      url: '/lists/getListProspectInformation',
      data: { listIds: payload.listIds },
      method: 'POST',
    });

    return res;
  } catch (err) {
    return console.error('Error in getListsProspectInformation', err);
  }
};

/**
 * Get lists subscriptions.
 */
export const getListsSubscriptions = async () => {
  try {
    const data = await request({
      method: 'get',
      url: '/lists/subscriptions/',
    });

    if (data instanceof Error) {
      return console.error('Error in getListsSubscriptions', data);
    }

    return store.dispatch({
      type: listsActionTypes.LISTS_SET_LISTS_SUBSCRIPTIONS,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getListsSubscriptions', err);
  }
};

/**
 * Merges lists.
 *
 * @param payload.listIds - array
 * @param payload.name - string
 */
export const mergeLists = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.name) ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length) ||
      (payload && payload.listIds && payload.listIds.length < 2)
    ) {
      return console.error('Missing params in mergeLists', payload);
    }

    const data = await request({
      data: {
        lists: payload.listIds,
        name: payload.name,
      },
      method: 'post',
      url: '/lists/merge/',
    });

    if (data instanceof Error) {
      return console.error('Error in mergeLists', data);
    }

    showFlashMessage(tc.listHaveBeenCreated, 'success');
    return await getLists({});
  } catch (err) {
    return console.error('Error in removeLists', err);
  }
};

/**
 * Removes lists.
 *
 * @param payload.archived - bool - To know if the lists we remove is from archived lists or regular lists.
 * @param payload.listIds - array
 */
export const removeLists = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length)
    ) {
      return console.error('Missing params in removeLists', payload);
    }

    const data = await requestBody({
      data: {
        list_ids: payload.listIds,
      },
      method: 'delete',
      url: '/lists/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.genericFailMessage, 'fail');
      return console.error('Error in removeLists', data);
    }

    showFlashMessage(tc.listsHaveBeenRemoved, 'success');

    if (payload.archived) {
      const newLists = store
        .getState()
        .lists.listsArchived.filter(
          (archivedList) => !payload.listIds.includes(archivedList.id)
        );
      return store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS_ARCHIVED,
        payload: newLists,
      });
    } else if (payload.subscriptionLists) {
      const newLists = store
        .getState()
        .lists.subscriptionLists.filter(
          (subscriptionList) => !payload.listIds.includes(subscriptionList.id)
        );
      return store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS_SUBSCRIPTIONLISTS,
        payload: newLists,
      });
    } else {
      const newLists = store
        .getState()
        .lists.lists.filter((list) => !payload.listIds.includes(list.id));
      return store.dispatch({
        type: listsActionTypes.LISTS_SET_LISTS,
        payload: newLists,
      });
    }
  } catch (err) {
    return console.error('Error in removeLists', err);
  }
};

export const removeListsContracts = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  if (!payload || !payload.ids || !payload.ids.length) {
    return console.error('Missing params in removeListsContracts: ', payload);
  }

  try {
    const data = await requestBody({
      data: { ids: payload.ids },
      method: 'delete',
      url: '/lists/contracts',
    });

    if (data instanceof Error) {
      return console.error('Error in removeListsContracts: ', data);
    }

    showFlashMessage(tc.listsHaveBeenRemoved, 'success');
    return await data;
  } catch (e) {
    console.error('Error in removeListsContracts: ', e);
  }
};

/**
 * Remove lists subscriptions.
 *
 * @param payload.ids - array - Lists subscriptions ids.
 */
export const removeListsSubscriptions = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.ids) ||
      (payload && payload.ids && !payload.ids.length)
    ) {
      return console.error(
        'Missing params in removeListsSubscriptions',
        payload
      );
    }

    const data = await requestBody({
      data: {
        ids: payload.ids,
      },
      method: 'delete',
      url: '/lists/subscriptions/',
    });

    if (data instanceof Error) {
      return console.error('Error in removeListsSubscriptions', data);
    }

    showFlashMessage(tc.listsSubscriptionsHaveBeenRemoved, 'success');
    return await getListsSubscriptions();
  } catch (err) {
    return console.error('Error in removeListsSubscriptions', err);
  }
};

/**
 * Save prospect to a new or existing list.
 *
 * @param payload.name - string - Name of new list.
 * @param payload.lists - array - Ids of existing lists.
 * @param payload.prospectIds - array - Prospects ids.
 */
export const saveProspectsToList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.name && !payload.lists) ||
      (payload &&
        (!payload.prospectIds ||
          (payload.prospectIds && !payload.prospectIds.length)))
    ) {
      console.error('Missing params in saveProspectsToList', payload);
      return showFlashMessage(tc.couldNotSaveToList, 'fail');
    }

    let data;

    if (payload.name && payload.name.length) {
      data = await request({
        data: {
          name: payload.name,
          prospect_ids: payload.prospectIds,
        },
        method: 'post',
        url: '/lists/',
      });
    } else {
      data = await request({
        data: {
          lists: payload.lists,
          prospect_ids: payload.prospectIds,
        },
        method: 'post',
        url: '/lists/append/',
      });
    }

    if (!data || data instanceof Error) {
      return console.error('Could not save prospects to list', data);
    }

    showFlashMessage(tc.savedInList, 'success');
    return getLists({});
  } catch (err) {
    return console.error('Error in saveProspectsToList', err);
  }
};

/**
 * Share lists.
 *
 * @param payload.listIds - array
 * @param payload.userIds - array
 */
export const shareLists = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length) ||
      (payload && !payload.userIds) ||
      (payload && payload.userIds && !payload.userIds.length)
    ) {
      return console.error('Missing params in shareLists', payload);
    }

    const data = await request({
      data: {
        lists: payload.listIds,
        users: payload.userIds,
      },
      method: 'post',
      url: '/lists/share/',
    });

    if (data instanceof Error) {
      return console.error('Error in shareLists', data);
    }

    showFlashMessage(tc.listsHaveBeenShared, 'success');
    return await getLists({});
  } catch (err) {
    return console.error('Error in shareListst', err);
  }
};

export const shareListSubscription = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.subscriptionId) ||
      (payload && !payload.userIds) ||
      (payload && payload.userIds && !payload.userIds.length)
    ) {
      return console.error('Missing params in shareListSubscription', payload);
    }

    const data = await request({
      data: {
        subscriptionId: payload.subscriptionId,
        users: payload.userIds,
      },
      method: 'post',
      url: '/lists/subscriptions/share',
    });

    if (data instanceof Error) {
      return console.error('Error in shareListSubscription', data);
    }

    showFlashMessage(tc.listsSubscriptionsHaveBeenShared, 'success');

    store.dispatch({
      type: listsActionTypes.LISTS_SET_LISTS_SUBSCRIPTIONS,
      payload: null,
    });

    return await getListsSubscriptions();
  } catch (err) {
    return console.error('Error in shareListSubscription', err);
  }
};

/**
 * Save list splits to new lists.
 *
 * @param payload.listId - string
 * @param payload.splits - array - [{size: 4, name: "Ny lista släpvagnar 1"}, {size: 4, name: "Ny lista släpvagnar 2"}]
 */
export const splitList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listId) ||
      (payload &&
        (!payload.splits || (payload.splits && !payload.splits.length)))
    ) {
      console.error('Missing params in splitList', payload);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    const splits = payload.splits.map((split) => {
      return {
        name: split.name,
        size: split.size,
        type: 'value',
      };
    });

    const data = await request({
      data: {
        listId: payload.listId,
        splits: splits,
      },
      method: 'post',
      url: '/lists/split/',
    });

    if (data instanceof Error) {
      return console.error('Error in splitList', data);
    }

    showFlashMessage(tc.listsHaveBeenCreated, 'success');
    return await getLists({});
  } catch (err) {
    return console.error('Error in splitList', err);
  }
};

/**
 * Move lists from archived to regular lists.
 *
 * @param payload.listIds - array
 */
export const undoArchive = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.listIds) ||
      (payload && payload.listIds && !payload.listIds.length)
    ) {
      return console.error('Missing params in undoArchives', payload);
    }

    const data = await request({
      data: {
        listIds: payload.listIds,
      },
      method: 'post',
      url: '/lists/undoArchive/',
    });

    if (data instanceof Error) {
      return console.error('Error in undoArchive', data);
    }

    showFlashMessage(tc.listsHaveBeenMoved, 'success');
    return await getLists({ archived: true });
  } catch (err) {
    return console.error('Error in undoArchive', err);
  }
};

/**
 * Update name on list.
 *
 * @param payload.listId
 * @param payload.name
 */
export const updateListName = async (payload) => {
  try {
    if (
      !payload ||
      (payload && !payload.listId) ||
      (payload && !payload.name)
    ) {
      return console.error('Missing params in updateListName', payload);
    }

    const data = await request({
      data: {
        listId: payload.listId,
        name: payload.name,
      },
      method: 'post',
      url: '/lists/changeName/',
    });

    if (data instanceof Error) {
      return console.error('Error in updateListName', data);
    }

    // Update name in store directly to avoid new backend call.
    let lists = store.getState().lists.lists;
    const newState = lists.map((list) => {
      if (list.id !== payload.listId) return list;
      return { ...list, name: payload.name };
    });

    return store.dispatch({
      type: listsActionTypes.LISTS_SET_LISTS,
      payload: newState,
    });
  } catch (err) {
    return console.error('Error in updateListName', err);
  }
};

/**
 * Get all of users customers
 *
 */
export const getAllMyCustomers = async () => {
  try {
    const data = await request({
      method: 'GET',
      url: '/responsibility/getAllMyCustomers/',
    });

    if (data instanceof Error) {
      return console.error('Error in undoArchive', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in undoArchive', err);
  }
};
