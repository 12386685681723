import { prospectActionTypes } from './actions';
import { prospectHelper } from 'helpers';

interface Data {
  car: {
    boughtCondition: Array<Value>;
    brandPercentages: object | Array<object>; // Special case - hold range object with brand name as key, objects are inserted when user actually selects to set percentage. Rebuilt to array when sending query to backend.
    brands: [] | Array<Value>;
    carAge: Range;
    carAgeFTIT: Range;
    carType: Array<Value> | [];
    carYear: Range;
    climateClassification: Array<Value>;
    changeTemp?: Array<Value>; // For users that has 'supertemp' access (SUPERTEMP_FLAG bit flag).
    co2: Range;
    coupling1: Array<Value>;
    coupling2: Array<Value>;
    dealerSalesmen?: [] | Array<Value>; // For users that has 'data integration' access (CUSTOMER_DATA_FLAG bit flag).
    dealerSalesmenType?: Array<Value>; // For users that has 'data integration' access (CUSTOMER_DATA_FLAG bit flag).
    duo: Array<Value>;
    emissionClass: Array<Value>;
    engineStrength: Range;
    finance: Array<Value>;
    fourwheel: Array<Value>;
    freeTextBrands: Array<Value>;
    freeTextModels: Array<Value>;
    fuel: Array<Value>;
    fuelDepletion_1: Range;
    height: Range;
    import: Array<Value>;
    inService: Array<Value>;
    inspectionExpiration: Range;
    inspectionOverdue: Range;
    inspectionValidity: Range;
    leasingOwners: [] | Array<Value>;
    leasingOwnersType: [] | Array<Value>;
    length: Range;
    maxExtraWeight: Range;
    maxLoadWeight: Range;
    possessionTime: Range;
    priorBrands: [] | Array<Value>;
    pulley: Array<Value>;
    aproxMileage: Range;
    regDate: Range;
    seatsBus: Range;
    seatsCar: Array<Value>;
    segmentsPBNew: Array<Value>; // We normally have segments in car type object, but for PB we have this additional segment for some reason.
    segmentTLBOld: Array<Value>;
    segmentLBOld: Array<Value>;
    segmentSVOld: Array<Value>;
    segmentsSVEuro: Array<Value>;
    segmentsTLBEuro: Array<Value>;
    segmentsLBEuro: Array<Value>;
    shaftAmount: Range;
    shaftDistance_1: Range;
    shaftDistance_2: Range;
    shaftDistance_3: Range;
    sellers: [] | Array<Value>;
    sellersGroups: [] | Array<Value>;
    sellersType: Array<Value>;
    service_weight: Range;
    submission: Array<Value>;
    supertempPrice: Range; // For users that has 'supertemp' access (SUPERTEMP_FLAG bit flag).
    weight: Range;
    width: Range;
    usage: Array<Value>;
  };
  combineWithData: {
    excludeUserIdsGroups: [] | Array<Value>;
    excludeSavedLists: [] | Array<Value>;
    excludeMyCustomers: Value;
    exclusiveUserIdsGroups: [] | Array<Value>;
    exclusiveContracts: [] | Array<Value>;
    exclusiveMyCustomers: [] | Array<Value>;
    exclude: [] | Array<Value>;
    excludeListsInverted: Value;
  };
  prospect: {
    prospectTypes: Array<Value>;
    operation: {
      dealer: Array<Value>;
      filial: Array<Value>;
      government: Array<Value>;
      leasing_company: Array<Value>;
      rental: Array<Value>;
      scrap: Array<Value>;
    };
    vehicleCountPB: Range;
    vehicleCountLB: Range;
    vehicleCountTLB: Range;
    vehicleCountATR: Range;
    vehicleCountATV: Range;
    vehicleCountBU: Range;
    vehicleCountHB: Range;
    vehicleCountHV: Range;
    vehicleCountMC: Range;
    vehicleCountMP: Range;
    vehicleCountMR: Range;
    vehicleCountSS: Range;
    vehicleCountSV: Range;
    vehicleCountTR: Range;
    carsOwned: RangeUnbounded;
    company: {
      vehicleUsers: Array<Value>;
      companyTypes: [] | Array<Value>;
      sniCodes: Array<Value>;
      sniCodesExcluded: Array<Value>;
      numEmployees: Array<Value>;
      groupInsurance: Array<Value>;
      revenue: Range;
      solidity: Range;
      excludeSniCodes: Value;
    };
    privatePerson: {
      age: Range;
      gender: Array<Value>;
    };
  };
  regions: {
    regions: [] | Array<Value>;
  };
  atlasRegions: {
    atlasRegions: [] | Array<Value>;
  };
}

// One of our two value holder types. This is what we use when setting a range.
interface Range {
  from: number | null;
  max: number;
  min: number;
  text?: string;
  to: number | null;
  type: string;
  unit: string;
  selected?: string;
  options?: string[];
}

interface RangeUnbounded {
  from: number;
  min: number;
  to: number | null;
  text?: string;
  type: string;
  unit: string;
}

// The other of our value holder types. This is what we use when setting a specific value.
interface Value {
  active: boolean;
  children?: Array<Value> | [];
  text: string;
  type: string;
  val: string | number;
}

interface ProspectState {
  activePreset: string | null;
  backendDataIsLoaded: boolean; // Flag for when backend data is loaded.
  count: {
    total: number | null;
    users: number | null;
  };
  data: Data;
  groups: {
    dealer: Array<object> | null;
    userIds: Array<object> | null;
  }; // We have groups in data object as well as in its own property to easily work with them.
  hideFields: object;
  loading: boolean; // Universal flag, use where applicable. For example used to wait for prospect search results and to avoid multiple calls for backend data.
  presets: Array<object>;
  recreateSearchLoading: boolean; // "loading" flag is not sufficient for this
  regionStatus: object;
  searchResult: {
    data: Array<object>;
    total: object;
  } | null;
  searchQuery: object | null; // Holds last search query.
}

const initialState: ProspectState = {
  activePreset: null,
  backendDataIsLoaded: false,
  count: {
    total: null,
    users: null,
  },
  data: prospectHelper.getDataObjectTyped(true),
  groups: {
    dealer: null,
    userIds: null,
  },
  hideFields: prospectHelper.getHideFieldsObject(),
  loading: false,
  presets: [],
  recreateSearchLoading: false,
  regionStatus: {},
  searchQuery: null,
  searchResult: null,
};

export const prospectReducer = (state = initialState, action) => {
  switch (action.type) {
    case prospectActionTypes.PROSPECT_SET_ACTIVE_PRESET: {
      return {
        ...state,
        activePreset: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_BACKEND_DATA_IS_LOADED: {
      return {
        ...state,
        backendDataIsLoaded: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_COUNT: {
      return {
        ...state,
        count: action.payload.count,
        searchQuery: action.payload.query,
      };
    }
    case prospectActionTypes.PROSPECT_SET_DATA: {
      // Set updated data to local storage.
      const query = prospectHelper.createQueryObjectFromDataObject({
        data: action.payload,
      });

      localStorage.setItem('bilprospekt-prospect-data', JSON.stringify(query));

      return {
        ...state,
        data: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_DATA_SELLERS_GROUPS: {
      return {
        ...state,
        data: {
          ...state.data,
          car: {
            ...state.data.car,
            sellersGroups: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_DATA_USER_IDS_GROUPS: {
      return {
        ...state,
        data: {
          ...state.data,
          combineWithData: {
            ...state.data.combineWithData,
            excludeUserIdsGroups: action.payload,
            exclusiveUserIdsGroups: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_DEALER_SALESMEN: {
      return {
        ...state,
        data: {
          ...state.data,
          car: {
            ...state.data.car,
            dealerSalesmen: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_FREETEXT_BRANDS: {
      return {
        ...state,
        data: {
          ...state.data,
          car: {
            ...state.data.car,
            freeTextBrands: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_GROUPS_DEALER_GROUPS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          dealer: action.payload,
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_GROUPS_USER_IDS_GROUPS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          userIds: action.payload,
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_HIDE_FIELDS: {
      return {
        ...state,
        hideFields: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_PRESETS: {
      return {
        ...state,
        presets: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_ATLAS_REGIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          atlasRegions: {
            atlasRegions: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_POSTORT_PRESETS: {
      return {
        ...state,
        postortPresets: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_RECREATE_SEARCH_LOADING: {
      return {
        ...state,
        recreateSearchLoading: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_REGIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          regions: {
            regions: action.payload,
          },
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_REGIONS_ACTIVE_PRESET: {
      return {
        ...state,
        regionStatus: {
          ...state.regionStatus,
          activePreset: action.payload,
        },
      };
    }
    case prospectActionTypes.PROSPECT_SET_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: action.payload,
      };
    }
    case prospectActionTypes.PROSPECT_SET_CONTRACTS: {
      return {
        ...state,
        data: {
          ...state.data,
          combineWithData: {
            ...state.data.combineWithData,
            exclusiveContracts: action.payload,
            excludeContracts: action.payload,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
