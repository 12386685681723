export default function PieChartCustomLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  count,
  ...props
}) {
  const RADIAN = Math.PI / 180;

  const radius = innerRadius + 32;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentage = percent * 100;
  if (percentage < 5) return null;
  return (
    <text
      x={x}
      y={y}
      fill="#FF2899"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {count} ({`${percentage.toFixed(0)}%`})
    </text>
  );
}
