import { useState } from 'react';
import DealerMenu from '../dealer_menu/dealer_menu';
import UserMenu from '../user_menu/user_menu';
import styles from './filter_menu.module.css';

export default function FilterMenu({
  users,
  selectedUsers,
  dealers,
  selectedDealers,
  onClickUser,
  onClickDealer,
}) {
  return (
    <div className={styles.container}>
      <UserMenu
        users={users}
        onClick={onClickUser}
        selectedUsers={selectedUsers}
      />
      <DealerMenu
        dealers={dealers}
        onClick={onClickDealer}
        selectedDealers={selectedDealers}
      />
    </div>
  );
}
