import { Dropdown, DropdownItem } from 'components/dropdown';
import styles from './user_menu.module.css';
import Collapsible from 'components/collapsible';
import { useContext } from 'react';
import LanguageContext from 'language-context';

export default function UserMenu({ users, onClick, selectedUsers }) {
  const tc = useContext(LanguageContext);
  return (
    <div>
      <Dropdown displayValue={tc.chooseUser} transparent={true}>
        {[...users]
          .sort((a, b) => {
            const [dealerNameA] = a;
            const [dealerNameB] = b;
            if (dealerNameA > dealerNameB) {
              return 1;
            } else if (dealerNameB > dealerNameA) {
              return -1;
            } else {
              return 0;
            }
          })
          .map((user) => {
            const [dealerName, dealerUsers] = user;
            return (
              <Collapsible
                key={dealerName}
                header={<h4>{dealerName}</h4>}
                content={[...dealerUsers]
                  .sort((a, b) => {
                    const aSelected = selectedUsers.includes(a.id);
                    const bSelected = selectedUsers.includes(b.id);
                    if (!bSelected && !aSelected)
                      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                    if (bSelected) return 1;
                    if (aSelected) return -1;
                    return 0;
                  })
                  .map((dealerUser) => {
                    return (
                      <DropdownItem
                        key={dealerUser.id}
                        label={dealerUser.name}
                        onClick={() => onClick(dealerUser.id)}
                        active={selectedUsers.includes(dealerUser.id)}
                      />
                    );
                  })}
              />
            );
          })}
      </Dropdown>
    </div>
  );
}
