import { activityHelper } from 'helpers';
import LanguageContext from 'language-context';
import { useContext } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MonthlyActivityGoalsFallback from './fallback';
const colors = {
  green: '#488f31',
  blue: '#4e4ee6',
};

export default function MonthlyActivityGoals({ height, width, data = [] }) {
  if (!data.every((obj) => typeof obj.goal === 'number' && obj.action))
    return <MonthlyActivityGoalsFallback height={height} width={width} />;

  return width && width > 0 ? (
    <div>
      <ActivityGoalsChart height={height} width={width} data={data} />
    </div>
  ) : (
    <ResponsiveContainer height={height} width={'99%'}>
      <ActivityGoalsChart data={data} />
    </ResponsiveContainer>
  );
}

function ActivityGoalsChart({ data, height, width }) {
  const tc = useContext(LanguageContext);
  const keys = {
    done: tc.complete,
    action: tc.activity,
    goal: tc.activityGoalsUpdate.split(' ')[0],
  };
  const dataFixed = data.map((obj) => {
    const { action, done, goal } = obj;
    return {
      [keys.done]: done,
      [keys.action]: activityHelper.getReadableActivity(action),
      [keys.goal]: goal,
    };
  });
  return (
    <>
      <h4>{tc.activityGoals}</h4>
      <BarChart
        data={dataFixed}
        margin={{ top: 15, right: 15, left: -20, bottom: 15 }}
        width={width}
        height={height}
      >
        <XAxis dataKey={keys.action} />
        <YAxis />
        <CartesianGrid strokeDasharray="1" />
        <Tooltip />
        <Bar dataKey={keys.done} fill={colors.green} stroke={colors.green} />
        <Bar dataKey={keys.goal} fill={colors.blue} stroke={colors.blue} />
        <Legend />
      </BarChart>
    </>
  );
}
