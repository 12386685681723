import clsx from 'clsx';
import styles from './chart_container.module.css';
export default function ChartContainer({ children, className, ...props }) {
  return (
    <article
      {...props}
      className={clsx(styles.wrapper, className && className)}
    >
      {children}
    </article>
  );
}
