import LanguageContext from 'language-context';
import { useContext } from 'react';
import { connect } from 'react-redux';
import styles from './user_fleet_preview.module.css';

const UserFleetPreview = ({ PB, LB, TLB, agileCardVehicleType }) => {
  const tc = useContext(LanguageContext);
  if (!agileCardVehicleType) return null;

  return (
    <div className={styles.wrapper}>
      {agileCardVehicleType === 'PB' && (
        <p>
          {tc.numberOfCarsPBCompact}: {PB}
        </p>
      )}
      {agileCardVehicleType === 'LB' && (
        <p>
          {tc.numberOfCarsLBCompact}: {LB}
        </p>
      )}
      {agileCardVehicleType === 'TLB' && (
        <p>
          {tc.numberOfCarsTLBCompact}: {TLB}
        </p>
      )}
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    agileCardVehicleType:
      state.user.settings.settings.agile.agileCardVehicleType,
    ...props,
  };
};
const UserFleetPreviewWithState = connect(mapStateToProps)(UserFleetPreview);
export default UserFleetPreviewWithState;
