import { previewActionTypes } from './actions';

/*
Example preview data object structure:
{
    car: {
        brands: [
            {
                label: 'Toyota',
                values: [
                    {
                    label: 'Auris'
                    },
                    {
                    label: 'Celica'
                    }
                }
            ]
        ],
        carType: [
            {
                label: 'Tung lastbil',
                values: [
                    {
                        label: 'Distribution',
                        values: [
                            {
                                label: 'Avfallshantering'
                            }
                        ],
                    }
                ],
            }
        ],
        carYear: {
            from: 1982,
            to: 2002,
        },
        brandPercentages: [
            {
                from: 20,
                to: 80,
                label: 'ALFA-ROMEO'
            }
        ],
    },
}
*/

interface PreviewState {
  data: object | null,
  icons: Array<string> | null,
  type: string,
  filters: any,
}

const initialState: PreviewState = {
  data: null,
  icons: null,
  type: '',
  filters: null,
};

export const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case previewActionTypes.PREVIEW_SET_DATA: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case previewActionTypes.PREVIEW_SET_ICONS: {
      return {
        ...state,
        icons: action.payload,
      }
    }
    case previewActionTypes.PREVIEW_SET_TYPE: {
      return {
        ...state,
        type: action.payload,
      }
    }
    case previewActionTypes.PREVIEW_SET_FILTER_DATA: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    default: {
      return state;
    }
  }
};
