import LanguageContext from 'language-context';
import moment from 'moment';
import { useContext } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function ActivityLineChart({ height, width, activities }) {
  const tc = useContext(LanguageContext);
  const defaultData = [-5, -4, -3, -2, -1, 0]
    .map((monthOffset) => {
      const current = new Date().getMonth();
      const month = monthOffset + current;
      if (month < 0) return month + 12;
      return month;
    })
    .map((month) => {
      return { count: 12, month };
    });
  const mapNames = (object) => {
    const monthName = moment(object.month + 1, 'M')
      .locale('se')
      .format('MMM');
    return {
      name: monthName.slice(0, 1).toUpperCase().concat(monthName.slice(1)),
      [tc.activities]: object.count,
    };
  };
  const data =
    activities.length === 0
      ? defaultData.map(mapNames)
      : activities.map(mapNames);
  const margins = { top: 15, right: 15, left: -20, bottom: 15 };

  return width && width > 0 ? (
    <div>
      <h4>{tc.lastSixMonthsActivity}</h4>
      <LineChart width={width} height={height} data={data} margin={margins}>
        <CartesianGrid strokeDasharray="1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={tc.activities} stroke="#8884d8" />
      </LineChart>
    </div>
  ) : (
    <ResponsiveContainer height={height} width="99%">
      <h4>{tc.lastSixMonthsActivity}</h4>
      <LineChart data={data} margin={margins}>
        <CartesianGrid strokeDasharray="1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={tc.activities} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
}
