import Info from 'components/info';
import styles from './centered_error.module.css';

export default function CenteredError({ errorText, errorHeader }) {
  return (
    <div className={styles.center}>
      <Info>
        <h4>{errorHeader}</h4>
        <p>{errorText}</p>
      </Info>
    </div>
  );
}
