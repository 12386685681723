import ConfirmRemoval from 'components/pdf_archive_manage_files/confirm_removal';
import PopupNew from 'components/popup/popup_new';
import LanguageContext from 'language-context';
import { useContext } from 'react';
import styles from './delete_all_leads.module.css';

export default function DeleteAllLeads({
  onConfirm,
  onCancel,
  onClose,
  total,
}) {
  const tc = useContext(LanguageContext);
  return (
    <PopupNew
      small={true}
      close={() => {
        onClose();
      }}
    >
      <div className={styles.wrapper}>
        <ConfirmRemoval
          label={`${
            tc.leadsYouAreDeleting
          } ${total} ${tc.exploreLeads.toLowerCase()}`}
          onConfirm={() => {
            onConfirm();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      </div>
    </PopupNew>
  );
}
