import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { tableHelper, miscHelper } from 'helpers';
import {
  prospectSearch,
  prospectAggs,
  savePreset,
  saveSupertempMonitorlist,
  saveToList,
} from 'store/prospect/tasks';
import { updateSettings } from 'store/user/tasks';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import Popup from 'components/popup';
import ProspectResultPreview from './prospect_result_preview';
import SaveToList from 'components/save_to_list';
import { TablePropsManaged } from 'components/table';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import AggsComponent from 'components/prospect/prospect_analyse/prospect_analyse';
import Info from 'components/info';
import { request } from 'helpers/request_helper';
import ActionButton from 'components/action_button';

const ProspectResult = (state) => {
  const [displayMode, setDisplayMode] = useState('prospects');
  const [inputName, setInputName] = useState('');
  const [page, setPage] = useState(0);
  const [previewObject, setPreviewObject] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSaveSupertempMonitorlist, setShowSaveSupertempMonitorlist] =
    useState(false);
  const [showSavePreset, setShowSavePreset] = useState(false);
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [aggsData, setAggsData] = useState(null);
  const [fileName, setFileName] = useState('');
  const [sort, setSort] = useState({
    order: 'asc',
    orderBy: '',
  });
  const [showExport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportError, setExportError] = useState(false);
  const [exportLink, setExportLink] = useState(null);
  const [aggsQuery, setAggsQuery] = useState(null);
  const inputNameRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (showSaveSupertempMonitorlist || showSavePreset) {
      inputNameRef && inputNameRef.current && inputNameRef.current.focus();
    }
  }, [showSaveSupertempMonitorlist, showSavePreset]);

  useEffect(() => {
    if (displayMode === 'cars' || displayMode === 'prospects') {
      prospectSearch({
        page: page,
        pageSize: rowsPerPage,
        sort: {
          direction: sort.order,
          type: sort.orderBy,
        },
        type: displayMode,
      });
    } else if (displayMode === 'aggs') {
      prospectAggs().then(({ data, query }) => {
        setAggsData(data);
        setAggsQuery(query);
      });
    }
  }, [displayMode, page, rowsPerPage, sort]);

  const _onSelect = (arr) => {
    if (displayMode === 'prospects') {
      setSelectedRows(
        state.prospect.searchResult.data.filter((num) =>
          arr.includes(num.user_id)
        )
      );
    } else if (displayMode === 'cars') {
      setSelectedRows(
        state.prospect.searchResult.data.filter((num) =>
          arr.includes(num.reg_number)
        )
      );
    }
  };

  const _saveToList = (payload) => {
    setShowSaveToList(false);
    saveToList({
      listIds: payload.lists,
      listName: payload.name,
      prospects: selectedRows.map((num) => num.user_id),
    });
  };

  const _exportToExcel = async () => {
    try {
      const filters = JSON.parse(
        localStorage.getItem('bilprospekt-prospect-filters')
      );

      let counties = [];
      let municipalities = [];
      let zipTowns = [];

      const regions = filters?.regions?.regions || [];

      if (regions.length) {
        counties = regions.map((r) => {
          if (r.values && r.values.length) {
            municipalities = municipalities.concat(
              r.values.map((m) => {
                if (m.values) {
                  zipTowns = zipTowns.concat(
                    m.values.map((z) => ({ label: z.label }))
                  );
                }
                return { label: m.label };
              })
            );
          }

          return { label: r.label };
        });
      }

      delete filters.regions;

      if (counties.length || municipalities.length || zipTowns.length) {
        filters.regions = {
          counties,
          municipalities,
          zipTowns,
        };
      }

      setExportLoading(true);
      const link = await request({
        url: '/prospect/aggs/export',
        method: 'POST',
        data: {
          aggsQuery,
          filters,
          filename: fileName,
        },
      });
      setExportLink(link.link);
      setExportLoading(false);
    } catch (err) {
      setExportLoading(false);
      setExportError(true);
    }
  };

  const _saveSearchAsPreset = () => {
    setShowSavePreset(false);
    savePreset({
      name: inputName,
    });
  };

  const _saveSupertempMonitorlist = () => {
    setShowSaveSupertempMonitorlist(false);
    saveSupertempMonitorlist({
      name: inputName,
    });
  };

  const _setPreviewObject = (id) => {
    let data;
    if (displayMode === 'prospects') {
      data = state.prospect.searchResult.data.find((num) => num.user_id === id);
    } else {
      data = state.prospect.searchResult.data.find(
        (num) => num.reg_number === id
      );
    }
    setPreviewObject(data);
  };

  const _stateCheck = () => {
    return !!(
      !state.prospect.loading &&
      state.prospect.searchResult?.data &&
      state.prospect.searchResult?.total
    );
  };

  return (
    <div className="prospectResultWrapper">
      <div className="prospectResultWrapper__prospectResult">
        <div className="prospectResultWrapper__prospectResult__header">
          <div className="prospectResultWrapper__prospectResult__header__top">
            <PageHeader
              headlineSub={
                _stateCheck()
                  ? displayMode === 'prospects'
                    ? `${tc.total} ${
                        state.prospect.searchResult.total.total
                      } ${tc.prospects.toLowerCase()}`
                    : `${tc.total} ${
                        state.prospect.searchResult.total.total
                      } ${tc.vehicles.toLowerCase()}`
                  : null
              }
            />
          </div>
          <div className="prospectResultWrapper__prospectResult__header__bottom">
            <Menu
              items={[
                {
                  disabled: !_stateCheck(),
                  label: tc.saveSearchAsPreset,
                  onClick: () => {
                    setInputName('');
                    setShowSavePreset(true);
                  },
                  type: 'button',
                },
                {
                  disabled: !_stateCheck(),
                  label: tc.saveToList,
                  onClick: () => {
                    setShowSaveToList(true);
                  },
                  type: 'button',
                },
                displayMode === 'aggs' && {
                  disabled:
                    !_stateCheck() || miscHelper.isRestricted(state.user),
                  label: tc.excelExport,
                  onClick: () => {
                    setShowExport(true);
                  },
                  type: 'button',
                },
                // state.prospect.data.car.changeTemp[0].active && {
                //   disabled: !_stateCheck(),
                //   label: tc.createSupertempMonitorlist,
                //   onClick: () => {
                //     setInputName("");
                //     setShowSaveSupertempMonitorlist(true);
                //   },
                //   type: "button",
                // },
                // Lägg till igen när vi vill kunna skapa/använda bevakningswidgets
              ]}
            />
          </div>
        </div>
        <div className="prospectResultWrapper__prospectResult__content">
          <div className="prospectResultWrapper__prospectResult__content__top">
            <div className="prospectResultWrapper__prospectResult__content__top__menu">
              <Menu
                centered={true}
                skipSort={true}
                type="floatFree"
                items={[
                  {
                    active: displayMode === 'prospects',
                    label: tc.prospectsView,
                    onClick: () => {
                      setDisplayMode('prospects');
                      setSelectedRows([]);
                    },
                    type: 'button',
                  },
                  {
                    active: displayMode === 'cars',
                    label: tc.vehiclesView,
                    onClick: () => {
                      setDisplayMode('cars');
                      setSelectedRows([]);
                    },
                    type: 'button',
                  },
                  {
                    active: displayMode === 'aggs',
                    label: tc.homeServiceFlow5_1,
                    onClick: () => {
                      setDisplayMode('aggs');
                      setSelectedRows([]);
                    },
                    type: 'button',
                  },
                ]}
              />
            </div>
          </div>
          <div className="prospectResultWrapper__prospectResult__content__bottom">
            <div className="prospectResultWrapper__prospectResult__content__bottom__left">
              {_stateCheck() && displayMode !== 'aggs' ? (
                <TablePropsManaged
                  adjustToContainer={true}
                  columns={
                    displayMode === 'prospects'
                      ? tableHelper.getProspectColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.prospects
                              ?.columns,
                          numberOfMatchingCars: true,
                        })
                      : tableHelper.getVehicleColumns({
                          activeColumns:
                            state.user?.settings?.settings?.tables?.vehicles
                              ?.columns,
                          //hideAllSort: true,
                        })
                  }
                  onColumnsChange={(columns) => {
                    if (state.user?.settings?.settings?.tables) {
                      const type =
                        displayMode === 'prospects' ? 'prospects' : 'vehicles';

                      let settings = {
                        ...state.user.settings.settings,
                        tables: {
                          ...state.user.settings.settings.tables,
                          [type]: {
                            ...state.user.settings.settings.tables[type],
                            columns: columns,
                          },
                        },
                      };

                      updateSettings({
                        settings: settings,
                      });
                    }
                  }}
                  onSelect={_onSelect}
                  page={page}
                  rows={
                    displayMode === 'prospects'
                      ? tableHelper.getProspectRows({
                          onClick: _setPreviewObject,
                          numberOfMatchingCars: true,
                          rows: state.prospect.searchResult.data.length
                            ? state.prospect.searchResult.data
                            : [],
                        })
                      : tableHelper.getVehicleRows({
                          onClick: _setPreviewObject,
                          rows: state.prospect.searchResult.data.length
                            ? state.prospect.searchResult.data
                            : [],
                          historic: false,
                          koncern: false,
                        })
                  }
                  rowsPerPage={rowsPerPage}
                  selected={
                    displayMode === 'prospects'
                      ? selectedRows.map((num) => num.user_id)
                      : selectedRows.map((num) => num.reg_number)
                  }
                  total={state.prospect.searchResult.total.total}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  pageChange={(newPage) => {
                    setPage(newPage);
                  }}
                  rowsPerPageChange={(val) => {
                    setRowsPerPage(val);
                  }}
                  sort={(newSort) => {
                    setSort(newSort);
                  }}
                />
              ) : (
                displayMode !== 'aggs' && <Loading />
              )}
              {displayMode === 'aggs' && aggsData && (
                <div className="prospectResultAggsWrapper">
                  {aggsData.total ? (
                    Object.entries(aggsData.aggs).map(([key, value]) => (
                      <AggsComponent
                        data={
                          value?.meta?.nested
                            ? Object.entries(value.buckets).map(
                                ([key, value]) => ({ key, ...value })
                              )
                            : value.buckets
                        }
                        header={value?.meta?.title}
                        key={key}
                        total={aggsData.total}
                        type={value?.meta?.type}
                      />
                    ))
                  ) : (
                    <Info>
                      <div>{tc.noResultsAggs}</div>
                    </Info>
                  )}
                </div>
              )}
            </div>
            {previewObject ? (
              <div
                className={
                  previewObject
                    ? 'prospectResultWrapper__prospectResult__content__bottom__right'
                    : 'prospectResultWrapper__prospectResult__content__bottom__right minimize'
                }
              >
                <ProspectResultPreview
                  close={() => {
                    setPreviewObject(null);
                  }}
                  data={previewObject}
                />
              </div>
            ) : null}
          </div>
          {showSaveToList ? (
            <SaveToList
              close={() => {
                setShowSaveToList(false);
              }}
              save={_saveToList}
              view="new"
            />
          ) : null}
          {showSaveSupertempMonitorlist ? (
            <Popup
              close={() => {
                setShowSaveSupertempMonitorlist(false);
              }}
              size="medium"
            >
              <div className="genericPopupContentWrapper">
                <div className="genericPopupContentWrapper__genericPopupContent">
                  <div className="genericPopupContentWrapper__genericPopupContent__header">
                    <WidgetHeader headline={tc.createSupertempMonitorlist} />
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__content">
                    <p>{tc.createSupertempMonitorlistInfo1}</p>
                    <p>{tc.createSupertempMonitorlistInfo2}</p>
                    <p>{tc.createSupertempMonitorlistInfo3}</p>
                    <p>
                      <span>{tc.nameNewMonitorlist}:</span>
                      <input
                        onChange={(e) => {
                          setInputName(e.target.value);
                        }}
                        value={inputName}
                        ref={inputNameRef}
                      />
                    </p>
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__footer">
                    <WidgetFooter
                      buttonOneFunc={_saveSupertempMonitorlist}
                      buttonOneText={tc.save}
                      buttonTwoFunc={() => {
                        setShowSaveSupertempMonitorlist(false);
                      }}
                      buttonTwoText={tc.cancel}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          ) : null}
          {showSavePreset ? (
            <Popup
              close={() => {
                setShowSavePreset(false);
              }}
              size="medium"
            >
              <div className="genericPopupContentWrapper">
                <div className="genericPopupContentWrapper__genericPopupContent">
                  <div className="genericPopupContentWrapper__genericPopupContent__header">
                    <WidgetHeader headline={tc.saveSearchAsPreset} />
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__content">
                    <p>{tc.saveSearchAsPresetInfo1}</p>
                    <p>
                      <span>{tc.nameNewPreset}:</span>
                      <input
                        onChange={(e) => {
                          setInputName(e.target.value);
                        }}
                        value={inputName}
                        ref={inputNameRef}
                      />
                    </p>
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__footer">
                    <WidgetFooter
                      buttonOneFunc={_saveSearchAsPreset}
                      buttonOneText={tc.save}
                      buttonTwoFunc={() => {
                        setShowSavePreset(false);
                      }}
                      buttonTwoText={tc.cancel}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          ) : null}
          {showExport ? (
            <Popup
              close={() => {
                setShowExport(false);
              }}
              size="medium"
            >
              <div className="genericPopupContentWrapper">
                <div className="genericPopupContentWrapper__genericPopupContent">
                  <div className="genericPopupContentWrapper__genericPopupContent__header">
                    <WidgetHeader headline={tc.export} />
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__content">
                    {exportError ? (
                      <p>{tc.somethingWentWrong}</p>
                    ) : exportLoading ? (
                      <Loading />
                    ) : exportLink ? (
                      <ActionButton
                        type="highlight"
                        href={exportLink}
                        label={tc.downloadFile}
                      ></ActionButton>
                    ) : (
                      <p>{tc.excelExport}</p>
                    )}
                    {!exportLink && (
                      <div>
                        <label>
                          {tc.filename}
                          <input
                            placeholder={tc.chooseFilename}
                            value={fileName}
                            onChange={(e) => {
                              setFileName(e.target.value);
                            }}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="genericPopupContentWrapper__genericPopupContent__footer">
                    <WidgetFooter
                      buttonOneFunc={_exportToExcel}
                      buttonOneText={tc.createFile}
                      disableButtonOne={exportLink !== null}
                      buttonTwoFunc={() => {
                        setShowExport(false);
                      }}
                      buttonTwoText={tc.cancel}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    prospect: state.prospect,
    user: state.user,
  };
};

export default connect(MapStateToProps)(ProspectResult);
