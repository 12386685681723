import HeadOfSalesDashboard from './views/head_of_sales';
import PageHeader from 'components/page_header';
import { useContext, useEffect, useState } from 'react';
import { request } from 'helpers';
import { showFlashMessage } from 'store/flash_messages/tasks';
import Info from 'components/info';
import Loading from 'components/loading';
import styles from './dashboard.module.css';
import BasicSalesDashboard from './views/sales/sales';
import LanguageContext from 'language-context';

const Dashboard = () => {
  const [role, setRole] = useState({
    value: false,
    error: false,
    loading: false,
  });
  const tc = useContext(LanguageContext);

  useEffect(() => {
    async function fetchRole() {
      setRole((prev) => ({ ...prev, loading: true }));
      const result = await request({ method: 'GET', url: '/dashboard/role' });
      setRole((prev) => ({ ...prev, loading: false }));
      if (result instanceof Error) {
        setRole((prev) => ({ ...prev, value: false, error: true }));
        showFlashMessage(
          'Något gick fel vid hämtning av din instrumentpanel.',
          'fail'
        );
      } else {
        setRole((prev) => ({ ...prev, value: result, error: false }));
      }
    }
    fetchRole();
  }, []);

  const { value, error } = role;
  const userType = value;
  if (role.loading || error)
    return (
      <>
        <PageHeader />
        <div className={styles.center}>
          {role.loading && <Loading />}
          {error && (
            <div className={styles.center}>
              <Info>{tc.tryRefreshing}</Info>
            </div>
          )}
        </div>
      </>
    );

  return (
    <div className={styles.background}>
      <PageHeader />
      {(userType === 'salesman' || userType === null) && (
        <BasicSalesDashboard />
      )}
      {userType === 'head_of_sales' && <HeadOfSalesDashboard />}
    </div>
  );
};

export default Dashboard;
