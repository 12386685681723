import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LanguageContext from 'language-context';
import {
  getKoncern,
  getKoncernResponsible,
  setKoncernResponsible,
  getKoncernDeals,
} from 'store/koncern/tasks';
import ColleaguesDropdown from 'components/colleagues_dropdown/colleagues_dropdown';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';

const KoncernInformation = ({ id, info, aggs, responsible, deals }) => {
  const [changeResponsible, setChangeResponsible] = useState(false);
  const [responsibleObj, setResponsibleObj] = useState({});
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getKoncern({ id });
    getKoncernResponsible({ id });
    getKoncernDeals({ id });
  }, [id]);

  useEffect(() => {
    if (responsible) {
      if (responsible.responsibleUserName) {
        setResponsibleObj(responsible);
      } else {
        setResponsibleObj({
          responsibleUserId: null,
          responsibleUserName: '',
        });
      }
    }
  }, [responsible]);

  const _saveResponsible = async () => {
    setChangeResponsible(false);
    return await setKoncernResponsible({
      entityId: info.company_id,
      responsibleUserId: responsibleObj.responsibleUserId,
    });
  };

  return (
    <div className="companyInfoWrapper">
      <div className="companyInfoWrapper__companyInfo">
        <div className="companyInfoWrapper__companyInfo__header">
          <WidgetHeader headline={tc.info} headlineSub={tc.aboutKoncern} />
        </div>
        <div className="companyInfoWrapper__companyInfo__content">
          {info ? (
            <div className="companyInfoWrapper__companyInfo__content__navigate">
              <a
                href={`https://allabolag.se/${info.company_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {tc.navigateToAllaBolag}
                <Icon val="navigateNext" />
              </a>
            </div>
          ) : (
            <Loading small={true} />
          )}
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper">
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.owner}
              </p>
              {responsible ? (
                <>
                  {changeResponsible ? (
                    <div className="editable">
                      <ColleaguesDropdown
                        activeId={responsibleObj.responsibleUserId}
                        activeIds={[]}
                        activeName={responsibleObj.responsibleUserName}
                        transparent={true}
                        onClick={(id, name) => {
                          setResponsibleObj({
                            ...responsibleObj,
                            responsibleUserId: id,
                            responsibleUserName: name,
                          });
                        }}
                      />
                      <Tooltip tooltipContent={tc.cancel}>
                        <Icon
                          val="clear"
                          onClick={() => {
                            if (responsible) {
                              setResponsibleObj(responsible);
                            } else {
                              setResponsibleObj({
                                responsibleUserId: null,
                                responsibleUserName: '',
                              });
                            }

                            setChangeResponsible(false);
                          }}
                        />
                      </Tooltip>
                      <Tooltip tooltipContent={tc.save}>
                        <Icon
                          val="save"
                          onClick={() => {
                            _saveResponsible();
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="editable">
                      <p>
                        {responsibleObj.responsibleUserName?.length ? (
                          responsibleObj.responsibleUserName
                        ) : (
                          <span className="italic">{tc.noOwner}</span>
                        )}
                      </p>
                      <Tooltip tooltipContent={tc.changeOwner}>
                        <Icon
                          val="edit"
                          onClick={() => {
                            setChangeResponsible(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.company}
              </p>
              {info ? (
                <>
                  <p>{info.name ? info.name : '-'}</p>
                  <p>{info.company_id}</p>
                </>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.visitingAddress}
              </p>
              {info ? (
                <p>
                  {info.ba_gatuadress
                    ? `${info.ba_gatuadress ? info.ba_gatuadress : ''} ${
                        info.ba_postnr ? info.ba_postnr : ''
                      } ${info.ba_postort ? info.ba_postort : ''}`
                    : '-'}
                </p>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.postAddress}
              </p>
              {info ? (
                <p>
                  {info.ua_gatuadress
                    ? `${info.ua_gatuadress ? info.ua_gatuadress : ''} ${
                        info.ua_postnr ? info.ua_postnr : ''
                      } ${info.ua_postort ? info.ua_postort : ''}`
                    : '-'}
                </p>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.noEmployees}
              </p>
              {aggs ? (
                <p>{aggs.antanst_AB_X ? aggs.antanst_AB_X : '-'}</p>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.netSales + ' (TKR)'}
              </p>
              {aggs ? (
                <p>{aggs.netoms_AB_X ? aggs.netoms_AB_X : '-'}</p>
              ) : (
                <Loading small={true} />
              )}
            </div>
            <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
              <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                {tc.partOfDeals}
              </p>
              {deals ? (
                deals.length > 0 ? (
                  deals.map((d, i) => (
                    <span className="dealLink" key={d.id}>
                      <NavLink exact to={`/affar/${d.id}`}>
                        {d.name || tc.deal}
                      </NavLink>
                      {i !== deals.length - 1 && <span>{', '}</span>}
                    </span>
                  ))
                ) : (
                  <p>
                    <span className="italic">{tc.noDeals}</span>
                  </p>
                )
              ) : (
                <Loading small={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    info: state.koncern.info,
    aggs: state.koncern.aggs,
    deals: state.koncern.deals,
    responsible: state.koncern.responsible,
  };
};

export default connect(MapStateToProps)(KoncernInformation);
