import moment from 'moment';
import PlannedActivitySection from './planned_activity_section';
import styles from './weekly_activities.module.css';

export default function WeeklyActivities({ events }) {
  const today = moment();
  const tomorrow = moment().add(1, 'days');
  const todaysActivities = events.filter((event) => {
    const eventDate = moment(event.event_date);
    const isToday = today.isSame(eventDate, 'd');
    return isToday;
  });
  const tomorrowsActivities = events.filter((event) => {
    const eventDate = moment(event.event_date);
    const isTomorrow = tomorrow.isSame(eventDate, 'd');
    return isTomorrow;
  });
  const restOfActivities = events.filter((event) => {
    return !todaysActivities
      .concat(tomorrowsActivities)
      .map((evt) => evt.id)
      .includes(event.id);
  });
  const mapEvent = (event) => {
    return {
      id: event.id,
      name: event.name,
      type: event.action,
      date: moment(event.event_date).format('YYYY-MM-DD HH:mm'),
    };
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <PlannedActivitySection
          activities={todaysActivities.map(mapEvent)}
          sectionType={'today'}
        />
        <PlannedActivitySection
          activities={tomorrowsActivities.map(mapEvent)}
          sectionType={'tomorrow'}
        />
        <PlannedActivitySection
          activities={restOfActivities.map(mapEvent)}
          sectionType={'comingWeek'}
        />
      </div>
    </div>
  );
}
