import React, { useContext } from 'react';
import Loading from 'components/loading';
import thumb from '../../images/BP_Thumbnail.png';
import LanguageContext from 'language-context';

const ReportsLibrary = ({ reports, setReportId }) => {
  const tc = useContext(LanguageContext);
  return (
    <div className="pbiReportsWrapper">
      <div className="pbiReportsWrapper__pbiEmbedd">
        <div className="pbiReportsWrapper__pbiEmbedd__reportsLibrary">
          {reports?.length > 0 ? (
            reports.map((report) => (
              <div
                className="pbiReportsWrapper__pbiEmbedd__reportsLibrary__item"
                key={report.id}
                onClick={() => setReportId(report.id)}
              >
                <div className="pbiReportsWrapper__pbiEmbedd__reportsLibrary__item__headers">
                  <h3>{report.name}</h3>
                </div>
                <div className="pbiReportsWrapper__pbiEmbedd__reportsLibrary__item__icon">
                  <img
                    src={report.imageUrl ? report.imageUrl : thumb}
                    alt={
                      report.description
                        ? report.description
                        : tc.reportDefaultDescription
                    }
                    className="pbiReportsWrapper__pbiEmbedd__reportsLibrary__item__icon__img"
                  />
                </div>
                <div className="pbiReportsWrapper__pbiEmbedd__reportsLibrary__item__description">
                  <span>
                    {report.description
                      ? report.description
                      : tc.reportDefaultDescription}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsLibrary;
