import { activityHelper } from 'helpers';
import styles from './planned_activity_section.module.css';
import moment from 'moment';
import { useContext } from 'react';
import LanguageContext from 'language-context';
import { Link } from 'react-router-dom';

export default function PlannedActivitySection({ activities, sectionType }) {
  const tc = useContext(LanguageContext);
  const toLabel = (str) => {
    switch (str) {
      case 'today':
        return tc.today;
      case 'tomorrow':
        return tc.tomorrow;
      case 'comingWeek':
        return tc.upcoming;
      default:
        return 'Text saknas';
    }
  };
  return (
    <div className={styles.wrapper}>
      <p className={styles.sectionHeader}>{toLabel(sectionType)}</p>
      {activities.length === 0 && <p>{tc.eventsMissing}</p>}
      {activities.map((activity) => {
        return (
          <Link to="/bearbeta" className={styles.event} key={activity.id}>
            <p className={styles.eventName}>{activity.name}</p>
            <p>
              {activityHelper.getReadableActivity(activity.type, true)} -{' '}
              {moment(activity.date).format('dddd HH:mm')}
            </p>
          </Link>
        );
      })}
    </div>
  );
}
