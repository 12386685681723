import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSettings,
  setSettingsActivePageOrSection,
  setShowSettings,
} from 'store/user/tasks';
import cssVariables from '../../styles/_exported_variables.module.scss';
import settingsHelper from 'shared_helpers/settings_helper';
import LanguageContext from 'language-context';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Popup from 'components/popup';
import SettingsAgile from './settings_agile';
import SettingsGrid from './settings_grid';
import SettingsOrders from './settings_orders';
import SettingsProspect from './settings_prospect';
import SettingsUniversal from './settings_universal';
import SettingsAdmin from './settings_admin';
import SettingsHubspot from './settings_hubspot';
import { miscHelper } from 'helpers';

/**
 * Handle user setting, renders as popup.
 */
const Settings = (state) => {
  const tc = useContext(LanguageContext);
  const [navigation, setNavigation] = useState([]);
  const canSetGoals =
    state.user.info.status & settingsHelper.status.setActivityGoals;

  let scrollTimeout;

  useEffect(() => {
    return () => clearTimeout(scrollTimeout);
  }, [scrollTimeout]);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    /*
     If we add anything to this:
     "page" and "section" values should exist in text-content.

     "section" values should exist as id on elements with class: "settingsPageWrapper__settingsPage__section"
     "section" values should exist as id on elements with class: "settingsWrapper__settings__content__left__item__row sub"

     Example section element inside the SettingsUniversal: <div className="settingsPageWrapper__settingsPage__item" id="editPassword">
     Example sub row element in navigation: <div className="settingsWrapper__settings__content__left__item__row sub" id="editPassword">

     Each page is expecting to contain at least one child section.
     Section values should be unique.
    */
    setNavigation([
      {
        icon: 'user',
        page: 'universal',
        children: [
          { section: 'user' },
          { section: 'editPassword' },
          { section: 'emailNotifications' },
          { section: 'contactUs' },
          { section: 'termsAndConditions' },
        ],
      },
      {
        icon: 'agile',
        page: 'agile',
        children: [
          { section: 'manageColumns' },
          { section: 'manageActivityTypes' },
          { section: 'manageVehicleType' },
        ],
      },
      {
        icon: 'orders',
        page: 'toOrder',
        children: [{ section: 'pdfArchive' }],
      },
      {
        icon: 'prospectSearch',
        page: 'prospectSearch',
        children: [
          { section: 'toggleVehicleTypes' },
          { section: 'groupsPostcodes' },
          { section: 'groupsDealer' },
          { section: 'groupsUserIdsShort' },
          { section: 'prospectPresets' },
        ],
      }, // Temp check. At some point we should remove the check on user level and keep the check for dealerStatus.
      miscHelper.hasHubspotIntegrationPermission(state.user)
        ? {
            icon: 'hub',
            page: 'integrations',
            children: [{ section: 'hubspot' }],
          }
        : null,
      !!(state.user?.info?.status & settingsHelper.status.setActivityGoals) &&
      !!(state.user?.info.status & 1)
        ? {
            icon: 'admin',
            page: 'admin',
            children: [{ section: 'activityGoals' }],
          }
        : null,
    ]);
  }, [state.user.info, canSetGoals]);

  /**
   * If looking to open or scroll to a certain page/section when opening settings from a specific route,
   * add the route (with a leading slash) to this object.
   */
  const locationRelationships = {
    '/': { page: 'universal', section: 'user' },
    '/prospektera': { page: 'prospectSearch', section: 'toggleVehicleTypes' },
    '/bearbeta': { page: 'agile', section: 'manageColumns' },
    '/listor/bestalla': { page: 'toOrder', section: 'pdfArchive' },
    '/analysera': { page: 'analyse', section: 'toggleWidgetsAnalyse' },
    '/grid': { page: 'dashboard', section: 'toggleWidgets' },
  };

  const actOnActivePath = (path) => {
    if (Object.keys(locationRelationships).includes(path)) {
      setSettingsActivePageOrSection(locationRelationships[path]);
    } else {
      setSettingsActivePageOrSection({
        page: navigation[0] ? navigation[0].page : 'universal',
        section: navigation[0] ? navigation[0].children[0].section : 'user',
      });
    }
  };
  useEffect(() => {
    if (!state.user?.settingsActivePage) {
      actOnActivePath(window.location.pathname);
    }
  }, [state.user.settingsActivePage]);

  useEffect(() => {
    // Important, when unmount reset page and section.
    return () => setSettingsActivePageOrSection({ page: null, section: null });
  }, []);

  useEffect(() => {
    // Set active page and section based on window location.
    // If values is already set in redux state that has precedence.
    if (!state.user?.settingsActivePage) {
      if (window.location.pathname === '/') {
        setSettingsActivePageOrSection({
          page: 'universal',
          section: 'user',
        });
      } else if (window.location.pathname.includes('prospektera')) {
        setSettingsActivePageOrSection({
          page: 'prospectSearch',
          section: 'toggleVehicleTypes',
        });
      } else if (window.location.pathname.includes('/bearbeta')) {
        setSettingsActivePageOrSection({
          page: 'agile',
          section: 'manageColumns',
        });
      } else if (window.location.pathname.includes('listor/bestalla')) {
        setSettingsActivePageOrSection({
          page: 'toOrder',
          section: 'pdfArchive',
        });
      } else {
        setSettingsActivePageOrSection({
          page: navigation[0] ? navigation[0].page : 'universal',
          section: navigation[0] ? navigation[0].children[0].section : 'user',
        });
      }
    }
  }, [state.user.settingsActivePage]);

  useEffect(() => {
    if (state.user?.settingsActiveSection) {
      // Listen for active section, adjust bottom padding for active page and scroll to it.
      _setBottomMarginLastSection();
      _scrollToSection(state.user.settingsActiveSection);
    }
  }, [state.user.settingsActiveSection]);

  useEffect(() => {
    // Add listeners for active section styling.
    const scrollableContainers = document.querySelectorAll(
      '.settingsWrapper__settings__content__right__page__content'
    );

    for (let i = 0, len = scrollableContainers.length; i < len; i++) {
      if (scrollableContainers[i]?.addEventListener) {
        scrollableContainers[i].addEventListener(
          'scroll',
          _setSectionActiveClass
        );
      }
    }

    return () => {
      // Remove listeners.
      const scrollableContainers = document.querySelectorAll(
        '.settingsWrapper__settings__content__right__page__content'
      );

      for (let i = 0, len = scrollableContainers.length; i < len; i++) {
        if (scrollableContainers[i]?.removeEventListener) {
          scrollableContainers[i].removeEventListener(
            'scroll',
            _setSectionActiveClass
          );
        }
      }
    };
  }, []);

  useEffect(() => {
    // Add listeners for bottom padding.
    const settingsWrapper = document.querySelector('.settingsWrapper');

    if (settingsWrapper?.addEventListener && window.addEventListener) {
      settingsWrapper.addEventListener('resize', _setBottomMarginLastSection);
      window.addEventListener('resize', _setBottomMarginLastSection);
    }

    return () => {
      // Remove listeners.
      const settingsWrapper = document.querySelector('.settingsWrapper');

      if (settingsWrapper?.removeListener && window.removeListener) {
        window.removeListener('resize', _setBottomMarginLastSection);
        settingsWrapper.removeListener('resize', _setBottomMarginLastSection);
      }
    };
  }, []);

  const _renderNavigation = () => {
    return navigation.map((num) => {
      if (!num) {
        return null;
      }

      return (
        <div
          className="settingsWrapper__settings__content__left__item"
          key={num.page}
        >
          <div
            className={`settingsWrapper__settings__content__left__item__row main ${
              state.user?.settingsActivePage === num.page
                ? 'settingsLeftItemMainActive'
                : 'settingsLeftItemMainInactive'
            }`}
            onClick={() => {
              setSettingsActivePageOrSection({
                page: num.page,
                section: num.children[0].section,
              });
            }}
          >
            <Icon val={num.icon} />
            <span>{tc[num.page]}</span>
          </div>
          {num.children.map((x) => {
            return (
              <div
                className={`settingsWrapper__settings__content__left__item__row sub ${
                  state.user?.settingsActivePage === num.page &&
                  state.user?.settingsActiveSection === x.section
                    ? 'settingsLeftItemSubActive'
                    : 'settingsLeftItemSubInactive'
                } ${
                  state.user?.settingsActivePage !== num.page
                    ? 'settingsLeftItemSubHidden'
                    : ''
                }`}
                id={x.section}
                onClick={() => {
                  setSettingsActivePageOrSection({
                    page: num.page,
                    section: x.section,
                  });
                  if (state.user?.settingsActiveSection === x.section) {
                    // If current section is already set to active, listener won't trigger the scroll - do it manually.
                    _scrollToSection(x.section);
                  }
                }}
                key={x.section}
              >
                {tc[x.section]}
              </div>
            );
          })}
        </div>
      );
    });
  };

  /**
   * Calculate and set bottom margin for last section in the active page, so scroll is working nicely to the top.
   */
  const _setBottomMarginLastSection = () => {
    const activePage = document.querySelector('.settingsRightActivePage');

    if (!activePage) {
      return;
    }

    const sections = activePage.querySelectorAll(
      '.settingsPageWrapper__settingsPage__section'
    );

    if (sections?.length) {
      const lastSection = sections[sections.length - 1];

      const lastSectionHeight =
        lastSection.getBoundingClientRect() &&
        lastSection.getBoundingClientRect().height > 0
          ? lastSection.getBoundingClientRect().height
          : lastSection.offsetHeight || lastSection.clientHeight;

      const activePageHeight =
        activePage.getBoundingClientRect() &&
        activePage.getBoundingClientRect().height > 0
          ? activePage.getBoundingClientRect().height
          : activePage.offsetHeight || activePage.clientHeight;

      const marginBottom =
        lastSectionHeight > activePageHeight
          ? +cssVariables.paddingBig.replace('px', '') * 3
          : activePageHeight - lastSectionHeight;

      lastSection.style.marginBottom = `${marginBottom}px`;
    }
  };

  /**
   * Set class on active section in navigation.
   */
  const _setSectionActiveClass = () => {
    const activePage = document.querySelector('.settingsRightActivePage');

    if (!activePage) {
      return;
    }

    const sections = activePage.querySelectorAll(
      '.settingsPageWrapper__settingsPage__section'
    );

    if (!sections?.length) {
      return;
    }

    const scrollableContainer = activePage.querySelector(
      '.settingsWrapper__settings__content__right__page__content'
    );

    if (!scrollableContainer) {
      return console.error('Could not find scrollable container in Settings');
    }

    const navItems = document.querySelectorAll(
      '.settingsWrapper__settings__content__left__item__row'
    );

    if (!navItems?.length) {
      return console.error('Could not find navigational items in Settings');
    }

    // Find where scroll position is at. 50 is certainly a magic number, works ok for selecting current section in settings navigation.
    const scroll = scrollableContainer.scrollTop + 50;

    //Previous values - did not work consistently for selecting and highlighting current section.
    // scrollableContainer.scrollTop + scrollableContainer.offsetHeight / 6;

    let activeId;

    sections.forEach((num, i) => {
      if (scroll > num.offsetTop) {
        // Scroll position has moved.
        if (
          sections[i + 1] &&
          sections[i + 1].offsetTop &&
          scroll < sections[i + 1].offsetTop
        ) {
          // Scroll position is between this and next section.
          activeId = num.id;
        } else {
          if (i === sections.length - 1) {
            // Last section so we cannot check next section.
            activeId = num.id;
          }
        }
      } else if (i === 0) {
        // Scroll position is on top, so first item should be active.
        activeId = num.id;
      }
    });

    navItems.forEach((num) => {
      if (num.id === activeId) {
        num.classList.add('settingsLeftItemSubActive');
      } else {
        num.classList.remove('settingsLeftItemSubActive');
      }
    });
  };

  /**
   * Scroll to active section with the active page.
   *
   * @param id
   */
  const _scrollToSection = (id) => {
    const activePage = document.querySelector('.settingsRightActivePage');

    if (!activePage) {
      return;
    }

    const scrollableContainer = activePage.querySelector(
      '.settingsWrapper__settings__content__right__page__content'
    );

    if (!scrollableContainer) {
      return;
    }

    const section = scrollableContainer.querySelector('#' + id);

    if (!section) {
      return;
    }

    scrollableContainer.scroll(
      0,
      section.offsetTop - scrollableContainer.offsetTop
    );

    const previousOffset = section.offsetTop;

    scrollTimeout = setTimeout(() => {
      if (section.offsetTop > previousOffset)
        scrollableContainer.scroll(
          0,
          section.offsetTop - scrollableContainer.offsetTop
        );
    }, 100);
  };

  const _stateCheck = () => {
    return !!state.user?.info;
  };

  return (
    <Popup
      borderRadius={true}
      close={() => {
        setShowSettings(false);
      }}
      noPadding={true}
      noScroll={true}
      size="xl"
    >
      <div className="settingsWrapper">
        <div className="settingsWrapper__settings">
          {_stateCheck() ? (
            <>
              <div className="settingsWrapper__settings__header">
                <div className="settingsWrapper__settings__header__close">
                  <div
                    className="settingsWrapper__settings__header__close__btn"
                    onClick={() => {
                      setShowSettings(false);
                    }}
                  >
                    <Icon val="clear" />
                    {tc.close}
                  </div>
                </div>
              </div>
              <div className="settingsWrapper__settings__content">
                <div className="settingsWrapper__settings__content__left">
                  {_renderNavigation()}
                </div>
                <div className="settingsWrapper__settings__content__right">
                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'universal'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.universal}
                    </div>
                    <div className="settingsWrapper__settings__content__right__page__content">
                      <SettingsUniversal />
                    </div>
                  </div>

                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'agile'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.agile}
                    </div>
                    <div className="settingsWrapper__settings__content__right__page__content">
                      <SettingsAgile />
                    </div>
                  </div>
                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'toOrder'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.toOrder}
                    </div>
                    <div className="settingsWrapper__settings__content__right__page__content">
                      <SettingsOrders />
                    </div>
                  </div>
                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'prospectSearch'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.prospectSearch}
                    </div>
                    <div className="settingsWrapper__settings__content__right__page__content">
                      <SettingsProspect />
                    </div>
                  </div>
                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'integrations'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.integrations}
                    </div>
                    {miscHelper.hasHubspotIntegrationPermission(state.user) ? (
                      <div className="settingsWrapper__settings__content__right__page__content">
                        <SettingsHubspot />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`settingsWrapper__settings__content__right__page ${
                      state.user?.settingsActivePage === 'admin'
                        ? 'settingsRightActivePage'
                        : 'settingsRightInactivePage'
                    }`}
                  >
                    <div className="settingsWrapper__settings__content__right__page__header">
                      {tc.admin}
                    </div>
                    <div className="settingsWrapper__settings__content__right__page__content">
                      <SettingsAdmin />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(Settings);
