import { useEffect, useState } from 'react';
import { miscHelper } from 'helpers';

/**
 *
 * @param {React.MutableRefObject} element ref to an element
 * @description Updates after 250ms without resizing.
 * @example const [width, height] = useElementSize(elementRef.current)
 * @returns [width: number, height: number]
 */
export function useElementSize(element) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (!element) return;
      setWidth(element.offsetWidth);
      setHeight(element.offsetHeight);
    }
    const debouncedResize = miscHelper.debounce(handleResize, 250);
    debouncedResize();
    window.addEventListener('resize', debouncedResize);
    if (element) element.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
      if (!element) return;
      element.removeEventListener('resize', debouncedResize);
    };
  }, [element]);
  return [width, height];
}
