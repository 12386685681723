import { miscHelper, request } from 'helpers';
import { useEffect } from 'react';
import { store } from 'store';
import { userActionTypes } from 'store/user/actions';
import VersionError from './version_error';

export default function VersionHandler({ type }) {
  useEffect(() => {
    async function checkVersion() {
      const data = await request({
        method: 'GET',
        url: '/checkVersion',
        data: {},
      });
      if (!data || !data.versionOutdated) {
        return;
      } else {
        if (type === data.versionOutdated) return;
        store.dispatch({
          type: userActionTypes.SET_USER_CLIENT_IS_OUT_OF_DATE,
          payload: data.versionOutdated,
        });
      }
    }
    checkVersion();
    const interval = setInterval(checkVersion, miscHelper.minsToMS(10));
    return () => clearInterval(interval);
  }, [type]);
  if (!type) return null;
  return <VersionError type={type} />;
}
