import { useContext } from 'react';
import ToggleOption from 'components/toggle_option';
import LanguageContext from 'language-context';
import { updateSettings } from 'store/user/tasks';

/**
 *
 * @param { {settings: {agile: { agileCardVehicleType: 'PB'|'LB'|'TLB' } } } } props
 * @description Component for displaying and changing vehicle type to display on pipeline prospect card
 */
const SettingsAgileCardVehicleType = ({ settings }) => {
  const options = ['PB', 'LB', 'TLB'];
  const tc = useContext(LanguageContext);

  return (
    <div
      id="manageVehicleType"
      className="settingsPageWrapper__settingsPage__section"
    >
      <div className="settingsPageWrapper__settingsPage__section__header">
        {tc.manageVehicleType}
      </div>
      <div className="settingsPageWrapper__settingsPage__section__content">
        <p>{tc.manageVehicleTypeInfo}</p>
      </div>
      <div className="settingsPageWrapper__settingsPage__section__content">
        <div className="toggleOptionsHolder">
          <div key={'none'} className="toggleOptionsHolder__item">
            <ToggleOption
              active={!settings.agile.agileCardVehicleType}
              label={tc.hide}
              onClick={() => {
                updateSettings({
                  settings: {
                    ...settings,
                    agile: {
                      ...settings.agile,
                      agileCardVehicleType: null,
                    },
                  },
                });
              }}
            />
          </div>
          {options.map((vehicleType) => {
            return (
              <div key={vehicleType} className="toggleOptionsHolder__item">
                <ToggleOption
                  active={settings.agile.agileCardVehicleType === vehicleType}
                  label={tc[vehicleType.toLowerCase()]}
                  onClick={() => {
                    updateSettings({
                      settings: {
                        ...settings,
                        agile: {
                          ...settings.agile,
                          agileCardVehicleType: vehicleType,
                        },
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SettingsAgileCardVehicleType;
