import React, { useEffect, useState, useRef, useContext } from 'react';
import LanguageContext from 'language-context';
import { cubejsClient } from '../../cubejs';
import { QueryRenderer } from '@cubejs-client/react';
import WidgetHeader from 'components/widget_header';
import Popup from 'components/popup';
import DataVisualisationOptions from './data_visualisation_options';
import settingsHelper from 'shared_helpers/settings_helper';
import InfoBox from 'components/analyse/info_box';
import DrillSelector from 'components/analyse/drill_selector';
import GranularitySelector from 'components/analyse/granularity_selector';
import ChartRenderer from 'components/analyse/chart_renderer';
import { granularities, getDynamicDateRange } from 'helpers/analysera_helper';
import { connect } from 'react-redux';

const DataVisualisation = ({
  query,
  drillDownOptions,
  title,
  subTitle,
  dateRange,
  dynamicDateRange,
  compareDateRange,
  filters,
  pivotConfig,
  defaultChartType,
  defaultDrillLevel,
  defaultGranularity,
  defaults,
  drillType,
  preview,
  fullWidth,
  noButtons,
  defaultShowLegend,
  user,
  getCurrentState,
  getCurrentMapData,
  type,
  initialMapState,
}) => {
  const [drillLevel, setDrillLevel] = useState(
    defaultDrillLevel ? defaultDrillLevel : 0
  );
  const [drillValues, setDrillValues] = useState([]);
  const [queries, setQueries] = useState([]);
  const [filtersReady, setFiltersReady] = useState(false);
  const [granularity, setGranularity] = useState(
    defaultGranularity ? defaultGranularity : 0
  );
  const [selectedChartStyle, setSelectedChartStyle] = useState(
    defaultChartType ? defaultChartType : 'bar'
  );
  const [showLegend, setShowLegend] = useState(
    defaultShowLegend ? defaultShowLegend : false
  );
  const [showOptions, setShowOptions] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [mapDrillQuery, setMapDrillQuery] = useState(null);
  const [mapObj, setMapObj] = useState(null);
  const [mapDet, setMapDet] = useState(null);
  const tc = useContext(LanguageContext);

  // Temp check. Remove check on user level and keep check on dealerStatus.
  // const hasAccess =
  //   !!(settingsHelper.dealerStatus.analyse & user?.info?.dealerStatus) &&
  //   !!(1 & user?.info.status);
  const hasAccess = true;

  useEffect(() => {
    if (mapObj) {
      mapObj.on('move', (e) => {
        setMapDet({
          lng: e.target.getCenter().lng.toFixed(4),
          lat: e.target.getCenter().lat.toFixed(4),
          zoom: e.target.getZoom().toFixed(2),
        });

        if (getCurrentMapData) {
          getCurrentMapData({
            lng: e.target.getCenter().lng.toFixed(4),
            lat: e.target.getCenter().lat.toFixed(4),
            zoom: e.target.getZoom().toFixed(2),
          });
        }
      });
    }
  }, [mapObj, getCurrentMapData]);

  useEffect(() => {
    setDrillLevel(0);

    setQueries([
      {
        query: {
          ...query,
          limit: selectedChartStyle === 'map' ? 50000 : query.limit,
          timeDimensions: query.timeDimensions.map((d) => ({
            ...d,
            dateRange: dynamicDateRange
              ? getDynamicDateRange(dynamicDateRange)
              : dateRange,
            granularity:
              drillType === 'time' && selectedChartStyle !== 'map'
                ? granularities[granularity]
                : null,
            compareDateRange,
          })),
          filters: filters
            .filter((x) => x.values?.length || x.operator === 'set')
            .concat(
              selectedChartStyle === 'map'
                ? type === 'analyseSales'
                  ? [
                      { member: 'Buyers.postort', operator: 'set' },
                      {
                        member: 'Buyers.postort',
                        operator: 'notEquals',
                        values: ['NO DATA'],
                      },
                      {
                        member: query.measures[0],
                        operator: 'gt',
                        values: ['0'],
                      },
                    ]
                  : [
                      { member: 'Users.postort', operator: 'set' },
                      {
                        member: 'Users.postort',
                        operator: 'notEquals',
                        values: ['NO DATA'],
                      },
                      {
                        member: query.measures[0],
                        operator: 'gt',
                        values: ['0'],
                      },
                    ]
                : []
            ),
          dimensions:
            drillType === 'dimension'
              ? selectedChartStyle === 'map'
                ? type === 'analyseSales'
                  ? ['Buyers.postort']
                  : ['Users.postort']
                : [drillDownOptions[granularity].dimension]
              : selectedChartStyle === 'map'
                ? type === 'analyseSales'
                  ? ['Buyers.postort']
                  : ['Users.postort']
                : undefined,
        },
      },
    ]);

    if (selectedChartStyle === 'map') {
      setMapDrillQuery({
        ...query,
        limit: 10,
        timeDimensions: query.timeDimensions.map((d) => ({
          ...d,
          dateRange: dynamicDateRange
            ? getDynamicDateRange(dynamicDateRange)
            : dateRange,
          granularity: drillType === 'time' ? granularities[granularity] : null,
          compareDateRange,
        })),
        filters: filters.filter(
          (x) => x.values?.length || x.operator === 'set'
        ),
        dimensions:
          drillType === 'dimension'
            ? [drillDownOptions[granularity].dimension]
            : undefined,
      });
    }

    if (getCurrentState) {
      getCurrentState({ drillLevel, granularity });
    }
    setFiltersReady(true);
  }, [
    dateRange,
    dynamicDateRange,
    filters,
    compareDateRange,
    granularity,
    selectedChartStyle,
  ]);

  return (
    filtersReady && (
      <div
        className={`analyseChartWrapper ${
          preview || fullWidth ? ' fullWidth' : ' halfWidth'
        }${noButtons && !preview ? ' noShadow' : ''}`}
      >
        {showOptions && hasAccess && (
          <Popup size="medium" close={() => setShowOptions(false)}>
            <DataVisualisationOptions
              myChart={{
                query: queries[drillLevel].query,
                drillDownOptions,
                title,
                subTitle,
                dateRange,
                compareDateRange,
                filters,
                pivotConfig,
                defaultDrillLevel: drillLevel,
                defaultGranularity: granularity,
                defaults,
                drillType,
                preview: true,
                defaultShowLegend: showLegend,
                defaultChartType: selectedChartStyle,
                type,
                mapDet,
              }}
              close={() => setShowOptions(false)}
              type={type}
            />
          </Popup>
        )}
        {showInfo && (
          <Popup size="medium" close={() => setShowInfo(false)}>
            <InfoBox
              data={{
                measures: queries[drillLevel].query.measures,
                dimensions: queries[drillLevel].query.dimensions || [],
                dateRange,
                dynamicDateRange,
                granularity: granularities[granularity],
                filters,
                selectedChartStyle,
                title,
              }}
            />
          </Popup>
        )}
        <div className="analyseChartWrapper__groupHeader">
          <div className="analyseChartWrapper__header">
            <WidgetHeader
              dashboardItems={[
                {
                  icon: 'alerts',
                  label: tc.info,
                  onClick: () => {
                    setShowInfo(!showInfo);
                  },
                },
                {
                  active: showLegend,
                  disabled: noButtons,
                  icon: 'legend_toggle',
                  label: tc.showLegend,
                  onClick: () => {
                    setShowLegend(!showLegend);
                  },
                  selectable: true,
                },
                {
                  active: selectedChartStyle === 'line',
                  disabled: noButtons,
                  label: `${tc.displayMode} ${tc.lineChart.toLowerCase()}`,
                  icon: 'line_chart',
                  onClick: () => {
                    setSelectedChartStyle('line');
                  },
                  selectable: true,
                },
                {
                  active: selectedChartStyle === 'bar',
                  disabled: noButtons,
                  label: `${tc.displayMode} ${tc.barChart.toLowerCase()}`,
                  icon: 'bar_chart',
                  onClick: () => {
                    setSelectedChartStyle('bar');
                  },
                  selectable: true,
                },
                {
                  active: selectedChartStyle === 'table',
                  disabled: noButtons,
                  label: `${tc.displayMode} ${tc.table.toLowerCase()}`,
                  icon: 'table_chart',
                  onClick: () => {
                    setSelectedChartStyle('table');
                  },
                  selectable: true,
                },
              ]}
              headline={title}
              headlineSub={subTitle}
            />
          </div>
          <div className="analyseChartWrapper__content__granularity cancelDrag">
            <GranularitySelector
              drillType={drillType}
              granularities={granularities}
              granularity={granularity}
              title={title}
              subTitle={subTitle}
              drillDownOptions={drillDownOptions}
              setGranularity={setGranularity}
              setDrillLevel={setDrillLevel}
              setDrillValues={setDrillValues}
            />
          </div>
          <div className="analyseChartWrapper__content__drilldown">
            {drillLevel > 0 && (
              <DrillSelector
                drillType={drillType}
                drillLevel={drillLevel}
                drillValues={drillValues}
                title={title}
                subTitle={subTitle}
                granularities={granularities}
                granularity={granularity}
                queries={queries}
                drillDownOptions={drillDownOptions}
                setDrillLevel={setDrillLevel}
                setDrillValues={setDrillValues}
                setQueries={setQueries}
              />
            )}
          </div>
        </div>
        <div
          className={`analyseChartWrapper__content__chart cancelDrag ${
            noButtons ? ' noShadow' : ''
          }`}
        >
          {selectedChartStyle !== 'table' && (
            <div className="analyseChartWrapper__content__chart__dummy"></div>
          )}
          <div
            className={
              selectedChartStyle === 'table'
                ? ''
                : 'analyseChartWrapper__content__chart__elementContainer'
            }
          >
            <QueryRenderer
              query={
                queries[drillLevel]?.query ? queries[drillLevel]?.query : {}
              }
              cubejsApi={cubejsClient}
              render={(renderProps) => (
                <ChartRenderer
                  {...renderProps}
                  selectedChartStyle={selectedChartStyle}
                  queries={queries}
                  drillLevel={drillLevel}
                  granularity={granularity}
                  drillDownOptions={drillDownOptions}
                  granularities={granularities}
                  drillValues={drillValues}
                  drillType={drillType}
                  query={query}
                  setDrillLevel={setDrillLevel}
                  setDrillValues={setDrillValues}
                  setQueries={setQueries}
                  showLegend={showLegend}
                  mapDrillQuery={mapDrillQuery}
                  setMapObj={setMapObj}
                  initialMapState={initialMapState}
                />
              )}
            />
          </div>
        </div>
      </div>
    )
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(DataVisualisation);
