import React, { useMemo } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  YAxis,
  Text,
  XAxis,
} from 'recharts';
import styles from './prospect_analyse.module.css';

const SimplePieChart = ({ data, total, header }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // const COLORS = ['#1A334A', '#264F73', '#457AA6', '#A2BBD2', '#E3EBF2'];

  const CustomToolTipFix = ({ active, payload, label }) => {
    return active ? (
      <div className={styles.tooltip}>{payload[0].name}</div>
    ) : null;
  };

  return (
    <div className={styles.analyse}>
      <h4>{header}</h4>
      <ResponsiveContainer width={'100%'} height={300} debounce={50}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={true}
            outerRadius={80}
            fill="#8884d8"
            dataKey="doc_count"
            nameKey="key"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            formatter={(value, entry, index) => {
              return `${value} (${((data[index].doc_count / total) * 100).toFixed(0)}%)`;
            }}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomToolTipFix />}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const HorizontalBarChart = ({ data: incData, total, header }) => {
  const blues = [
    ['#457AA6'],
    ['#457AA6', '#E3EBF2'],
    ['#264F73', '#457AA6', '#E3EBF2'],
    ['#264F73', '#457AA6', '#A2BBD2', '#E3EBF2'],
    ['#1A334A', '#264F73', '#457AA6', '#A2BBD2', '#E3EBF2'],
  ];

  const data = incData.map((row) => ({
    ...row,
    tooltipKey: row.key,
    key: String(row.key).slice(0, 12),
  }));

  data.sort((a, b) => b.doc_count - a.doc_count);

  const getColor = (length, index) => {
    if (length <= blues.length) {
      return blues[length - 1][index];
    }

    return blues[blues.length - 1][index % blues.length];
  };

  const YAxisLeftTick = ({ y, payload: { value } }) => {
    return (
      <Text y={y} textAnchor="start">
        {value}
      </Text>
    );
  };

  const BAR_AXIS_SPACE = 10;

  const yKey = 'doc_count';
  const xKey = 'key';

  let ctx;

  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      ctx = document.createElement('canvas').getContext('2d');
      ctx.font = "8px 'Helvetica Neue";
    }

    return ctx.measureText(text).width;
  };

  const getPercentage = (value) => `${((value / total) * 100).toFixed(0)}%`;

  const maxTextWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[yKey];
        const width = measureText14HelveticaNeue(
          `${value.toLocaleString()} 
           ${getPercentage(value)}`
        );
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data, yKey]
  );

  const maxTextWidthX = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[xKey];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data, xKey]
  );

  const CustomToolTipFix = ({ active, payload, label }) => {
    return active ? (
      <div className={styles.tooltip}>{payload[0].payload.tooltipKey}</div>
    ) : null;
  };

  return (
    <div className={styles.analyse}>
      <h4>{header}</h4>
      <ResponsiveContainer
        width={'100%'}
        height={50 * data.length}
        debounce={50}
      >
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            left: maxTextWidthX + (BAR_AXIS_SPACE - 8),
            right: maxTextWidth + (BAR_AXIS_SPACE - 8),
          }}
        >
          <XAxis hide axisLine={false} type="number" />
          <YAxis
            yAxisId={0}
            dataKey={xKey}
            type="category"
            axisLine={false}
            tickLine={false}
            tick={YAxisLeftTick}
          />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={yKey}
            type="category"
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) =>
              value.toLocaleString() +
              ` (${((value / total) * 100).toFixed(0)}%)`
            }
            mirror
            tick={{
              transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
            }}
          />
          <Bar dataKey={yKey} minPointSize={2} barSize={32}>
            {data.map((d, idx) => {
              return <Cell key={d[xKey]} fill={getColor(data.length, idx)} />;
            })}
          </Bar>
          <Tooltip
            content={<CustomToolTipFix />}
            cursor={{ fill: 'transparent' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const AggsComponent = ({ data, header, total, type }) => {
  return type === 'horizontalBar' ? (
    <HorizontalBarChart data={data} header={header} total={total} />
  ) : (
    <SimplePieChart data={data} header={header} total={total} />
  );
};

export default AggsComponent;
