import NumberStatistic from './number_statistic';
import styles from './number_statistics.module.css';

export default function NumberStatistics({ data = [], onClick }) {
  const defaults = [
    { parameter: 'activityCount', number: null, percentageChange: null },
    { parameter: 'wonDeals', number: null, percentageChange: null },
    { parameter: 'lostDeals', number: null, percentageChange: null },
  ];
  return (
    <div className={styles.wrapper}>
      {((data?.length && data) || defaults).map((stat) => {
        return (
          <NumberStatistic key={stat.parameter} onClick={onClick} {...stat} />
        );
      })}
    </div>
  );
}
