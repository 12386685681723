import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { updateDeal } from 'store/deal/tasks';
import LanguageContext from 'language-context';
import { agileHelper, dealHelper } from 'helpers';
import { Dropdown, DropdownItem } from 'components/dropdown';
import ColleaguesDropdown from 'components/colleagues_dropdown';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Tooltip from 'components/tooltip';
import WidgetHeader from 'components/widget_header';

/**
 *
 * @param state.props.dataIsCollected - bool - So we now when deal object is set in redux state.
 */
const DealMain = (state) => {
  const [editDeal, setEditDeal] = useState(false);
  const [dealObj, setDealObj] = useState({});
  const dealDescriptionInputRef = useRef(null);
  const dealNameInputRef = useRef(null);
  const dealPotentialInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (state.deal?.deal) {
      // Set properties that we should be able to edit. Make sure they correlate to params in updateDeal.
      setDealObj({
        description: state.deal.deal.description,
        name: state.deal.deal.name,
        potential: state.deal.deal.potential,
        user_id: state.deal.deal.user_id,
        userName: state.deal.deal.user?.name?.length
          ? state.deal.deal.user.name
          : '-', // This is for frontend display, when changing user we only send user id.
      });
    }
  }, [state.deal]);

  const _onInputChange = () => {
    let potential = dealObj.potential;
    if (
      dealPotentialInputRef?.current &&
      Number.isFinite(+dealPotentialInputRef.current.value)
    ) {
      // Make sure potential is a monetary value.
      potential = +dealPotentialInputRef.current.value;
    }

    setDealObj({
      ...dealObj,
      description: dealDescriptionInputRef.current.value,
      name: dealNameInputRef.current.value,
      potential: potential,
    });
  };

  const _saveChanges = async () => {
    setEditDeal(false);
    return await updateDeal({ ...dealObj, id: state.deal.deal.id });
  };

  const _setDealObjFromStore = () => {
    if (state.deal && state.deal.deal) {
      // Set properties that we should be able to edit. Make sure they correlate to params in updateDeal.
      setDealObj({
        description: state.deal.deal.description,
        name: state.deal.deal.name,
        potential: state.deal.deal.potential,
        user_id: state.deal.deal.user_id,
        userName: state.deal.deal.user.name, // This is for frontend display, when changing user we only care send user id.
      });
    }
  };

  const _stateCheck = () => {
    return !!state.props.dataIsCollected;
  };

  return (
    <div className="dealMainWrapper">
      <div className="dealMainWrapper__dealMain">
        <div className="dealMainWrapper__dealMain__header">
          <WidgetHeader
            dashboardItems={
              !editDeal
                ? [
                    {
                      disabled:
                        editDeal ||
                        state.user.info.id !== state.deal.deal.user_id,
                      icon: 'edit',
                      label: tc.editDeal,
                      onClick: () => {
                        setEditDeal(true);
                      },
                    },
                  ]
                : [
                    {
                      disabled: !editDeal,
                      icon: 'clear',
                      label: `${tc.cancel} ${tc.edit.toLowerCase()}`,
                      onClick: () => {
                        setEditDeal(false);
                        _setDealObjFromStore();
                      },
                    },
                    {
                      disabled: !editDeal,
                      icon: 'save',
                      label: tc.saveChanges,
                      onClick: _saveChanges,
                    },
                  ]
            }
            headline={tc.deal}
            headlineSub={
              _stateCheck() ? tc.dealMainInfo : <Loading small={true} />
            }
          />
        </div>
        {_stateCheck() ? (
          <div
            className={`dealMainWrapper__dealMain__content ${
              editDeal ? 'editDeal' : ''
            }`}
          >
            <div className="dealMainWrapper__dealMain__content__item">
              <p className="dealMainWrapper__dealMain__content__item__label">
                {tc.owner}
              </p>
              {editDeal ? (
                <ColleaguesDropdown
                  activeId={dealObj.user_id}
                  activeIds={[]}
                  activeName={dealObj.userName}
                  transparent={true}
                  onClick={(id, name) => {
                    setDealObj({
                      ...dealObj,
                      user_id: id,
                      userName: name,
                    });
                  }}
                />
              ) : (
                <p>
                  {dealObj.userName?.length ? (
                    dealObj.userName
                  ) : (
                    <span className="italic">{tc.noOwner}</span>
                  )}
                </p>
              )}
            </div>
            <div className="dealMainWrapper__dealMain__content__item">
              <p className="dealMainWrapper__dealMain__content__item__label">
                {tc.name}
              </p>
              {editDeal ? (
                <input
                  onChange={_onInputChange}
                  ref={dealNameInputRef}
                  type="text"
                  value={dealObj.name ? dealObj.name : ''}
                />
              ) : (
                <p>
                  {state.deal.deal.name ? (
                    state.deal.deal.name
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </p>
              )}
            </div>
            <div className="dealMainWrapper__dealMain__content__item">
              <p className="dealMainWrapper__dealMain__content__item__label">
                {tc.description}
              </p>
              {editDeal ? (
                <input
                  onChange={_onInputChange}
                  ref={dealDescriptionInputRef}
                  type="text"
                  value={dealObj.description ? dealObj.description : ''}
                />
              ) : (
                <p>
                  {dealObj.description ? (
                    dealObj.description
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </p>
              )}
            </div>
            {!editDeal ? (
              <div className="dealMainWrapper__dealMain__content__item">
                <p className="dealMainWrapper__dealMain__content__item__label">
                  {tc.status}
                </p>
                <p>
                  {agileHelper.buildPhaseToColumnTitle(state.deal.deal.phase)}
                </p>
              </div>
            ) : null}
            {!editDeal ? (
              <div className="dealMainWrapper__dealMain__content__item">
                <p className="dealMainWrapper__dealMain__content__item__label">
                  {tc.savedInList}
                </p>
                <p>
                  {state.deal.listName ? (
                    state.deal.listName
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </p>
              </div>
            ) : null}
            <div className="dealMainWrapper__dealMain__content__item">
              <p className="dealMainWrapper__dealMain__content__item__label">{`${tc.potential} (${tc.currency})`}</p>
              {editDeal ? (
                <input
                  className="small"
                  onChange={_onInputChange}
                  ref={dealPotentialInputRef}
                  type="text"
                  value={dealObj.potential ? dealObj.potential : ''}
                />
              ) : (
                <p>
                  {dealObj.potential ? dealObj.potential : <span>&nbsp;</span>}
                </p>
              )}
            </div>
            {!editDeal ? (
              <div className="dealMainWrapper__dealMain__content__item">
                <p className="dealMainWrapper__dealMain__content__item__label">
                  {tc.lastUpdate}
                </p>
                <p>
                  {moment(
                    new Date(state.deal.deal.updated),
                    'DD/MM/YYYY'
                  ).fromNow()}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    deal: state.deal,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(DealMain);
