/**
 * @description A lot of magic numbers in this function.
 * This is based on trial and error, wasn't able to find a good solution to make
 *  the charts properly responsive in the grid for the charts.
 * @returns number
 */
function calculateChartHeight(containerHeight, containerWidth) {
  const NUMBER_STATISTICS_HEIGHT = 100;
  if (containerHeight === 0 || containerWidth === 0) return 250; // default to avoid jarring changes on page load
  if (containerWidth > 500 && containerWidth <= 800) {
    return (containerHeight - NUMBER_STATISTICS_HEIGHT - 50) / 4.5;
  } else if (containerWidth <= 500) {
    const width = containerHeight / 6;
    return width;
  } else {
    return (containerHeight - NUMBER_STATISTICS_HEIGHT - 50) / 3;
  }
}
function calculateChartWidth(containerWidth) {
  if (containerWidth < 400) return containerWidth - 50;
  return undefined;
}

function calculateChartSize(containerHeight, containerWidth) {
  return [
    setDefault(calculateChartHeight(containerHeight, containerWidth)),
    calculateChartWidth(containerWidth),
  ];
}
function setDefault(size) {
  if (size < 0 || !size) return 250;
  return size;
}

export { calculateChartHeight, calculateChartWidth, calculateChartSize };
