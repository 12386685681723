import PopupNew from 'components/popup/popup_new';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styles from './activity_list.module.css';
import { activityHelper } from 'helpers';
import Icon from 'components/icon';
import { useContext } from 'react';
import LanguageContext from 'language-context';

export default function ActivityList({ activities = [], onClose }) {
  const tc = useContext(LanguageContext);
  return (
    <PopupNew
      close={() => {
        onClose();
      }}
    >
      <div className={styles.content}>
        <p className={styles.header}>{tc.activityMonthlyLatest}</p>
        {activities.map((activity) => {
          const { deal_id, prospect_id, action_type } = activity;
          const link =
            action_type === 'deal_action' && activity.deal_id
              ? `/affar/${deal_id}`
              : prospect_id > 1000000000
              ? `/foretag/${prospect_id}`
              : `/person/${prospect_id}`;
          return (
            <Link
              className={styles.activity}
              to={link}
              key={activity.id + action_type}
            >
              <Icon val={activity.action} class={styles.icon} />
              <div className={styles.activityInfo}>
                <p>{activity.name}</p>
                {activityHelper.getReadableActivity(activity.action)},{' '}
                {moment(activity.date_created)
                  .locale('se')
                  .format('YYYY-MM-DD')}
                <p>{activity.user_name}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </PopupNew>
  );
}
