import ReactDOM from 'react-dom';
import { store } from 'store';
import * as text from 'text-content';
import settingsHelper from 'shared_helpers/settings_helper';

export const miscHelper = {
  /**
   * Used for resize observer in components.
   * One visible element with a certain number of items.
   * One hidden element with all items.
   * Calculate how many more items we can fit in visible element, or how many we should remove.
   *
   * @param contentHolder
   * @param visibleItems
   * @param hiddenItems
   */
  calculateHeightAndItems: (contentHolder, visibleItems, hiddenItems) => {
    let result = {
      itemsExtra: 0,
      itemsRemove: 0,
    };

    if (
      !contentHolder?.getBoundingClientRect() ||
      !visibleItems ||
      !hiddenItems
    ) {
      return result;
    }

    const itemsHidden = Array.from(hiddenItems);
    const itemsVisible = Array.from(visibleItems);

    const isOK =
      itemsVisible &&
      itemsVisible[0] &&
      itemsVisible[0].getBoundingClientRect() &&
      itemsHidden &&
      itemsHidden[0] &&
      itemsHidden[0].getBoundingClientRect();

    if (!isOK) {
      return result;
    }

    // Space available. Don't know why but items sometimes have +1px per child height.
    const contentHeight =
      contentHolder.getBoundingClientRect().height + itemsVisible.length;

    // How much space used by items.
    let heightsAndGapsVisibleItems =
      itemsVisible[itemsVisible.length - 1].getBoundingClientRect().bottom -
      itemsVisible[0].getBoundingClientRect().top;

    // Actual element heights of items.
    const heightsVisibleItems = itemsVisible.reduce((tot, cur, i) => {
      if (cur?.getBoundingClientRect()) {
        tot = tot + cur.getBoundingClientRect().height + 1;
      }
      return tot;
    }, 0);

    if (heightsVisibleItems > heightsAndGapsVisibleItems) {
      heightsAndGapsVisibleItems = heightsVisibleItems;
    }

    // Gap between items, if any.
    let gap =
      (heightsAndGapsVisibleItems - heightsVisibleItems) /
        (itemsVisible.length - 1) >
      0
        ? (heightsAndGapsVisibleItems - heightsVisibleItems) /
          (itemsVisible.length - 1)
        : 0;

    if (heightsAndGapsVisibleItems > contentHeight) {
      itemsVisible.reverse();

      let heightDecr = heightsAndGapsVisibleItems;
      let itemsRemove = 0;
      let cancel = false;

      itemsVisible.forEach((item, i) => {
        if (cancel) {
          return;
        }

        if (heightDecr > contentHeight) {
          itemsRemove = itemsRemove + 1;
          heightDecr = heightDecr - (item.getBoundingClientRect().height + gap);
        } else {
          cancel = true;
        }
      });

      result.itemsRemove = itemsRemove;
    } else {
      let heightIncr = heightsAndGapsVisibleItems;
      let itemsExtra = 0;
      let cancel = false;

      itemsHidden.forEach((item, i) => {
        if (i <= itemsVisible.length - 1 || cancel) {
          return;
        }

        // Added 2 for weird diff I haven't figured out...
        const itemHeight = item.getBoundingClientRect().height + gap + 2;
        if (contentHeight > heightIncr + itemHeight) {
          itemsExtra = itemsExtra + 1;
          heightIncr = heightIncr + itemHeight;
        } else {
          cancel = true;
        }
      });

      result.itemsExtra = itemsExtra;
    }

    return result;
  },
  calendarMonths: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return {
      1: tc.january,
      2: tc.february,
      3: tc.march,
      4: tc.april,
      5: tc.may,
      6: tc.june,
      7: tc.july,
      8: tc.august,
      9: tc.september,
      10: tc.october,
      11: tc.november,
      12: tc.december,
    };
  },
  calendarWeekdays: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      tc.mondayAbbr,
      tc.tuesdayAbbr,
      tc.wednesdayAbbr,
      tc.thursdayAbbr,
      tc.fridayAbbr,
      tc.saturdayAbbr,
      tc.sundayAbbr,
    ];
  },
  /**
   * See if we should unmount an element based on click.
   * Used for dropdown, popups, preview panels etc.
   *
   * Still not great.
   * If something is rendered outside normal app node tree, and is used inside a component that is also rendered
   * outside normal app tree, we don't really handle that well.
   * For instance when <Dropdown> (rendered outside app tree with <Popper>) is used inside <Popup>.
   * The current solution is to go by location of click with param unmountByCoordinates, but that isn't really
   * a great solution. Because if we use a large popup/modal inside a small popup, the smaller popup
   * will unmount anytime a click is outside its container.
   * Please improve. :)
   *
   * @param event - event
   * @param containerRef - If click is outside this container, unmount.
   * @param unmountByCoordinates - bool - If true we compare container and click coordinates. Applies when a component is rendered with <Popper> outside regular app node tree, and is used inside a component that is also rendered outside app node tree. Like when <ControlComponent> is used in <Popup>, it's a modal inside a modal.
   * @param skipRouterCheck - bool - When something is rendered outside regular app node tree. Like <Dropdown> that is rendered with <Popper>.
   * @param extraContainerRef - Optional extra container.
   */
  clickUnmount: (
    event,
    containerRef,
    unmountByCoordinates,
    skipRouterCheck,
    extraContainerRef = null
  ) => {
    if (!event?.target || !containerRef?.current) {
      return false;
    }

    const clickedTarget = event.target;

    if (unmountByCoordinates) {
      const clickLeft = event.x;
      const clickTop = event.y;
      const containerLeft = containerRef.current.getBoundingClientRect().left;
      const containerRight = containerRef.current.getBoundingClientRect().right;
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const containerBottom =
        containerRef.current.getBoundingClientRect().bottom;

      return !(
        clickLeft >= containerLeft &&
        clickLeft <= containerRight &&
        clickTop >= containerTop &&
        clickTop <= containerBottom
      );
    } else if (skipRouterCheck) {
      if (extraContainerRef?.current) {
        const node = ReactDOM.findDOMNode(containerRef.current);
        const node2 = ReactDOM.findDOMNode(extraContainerRef.current);
        return !(
          (node && node.contains(clickedTarget)) ||
          (node2 && node2.contains(clickedTarget))
        );
      } else {
        const node = ReactDOM.findDOMNode(containerRef.current);
        return node && !node.contains(clickedTarget);
      }
    } else {
      if (extraContainerRef?.current) {
        const node = ReactDOM.findDOMNode(containerRef.current);
        const node2 = ReactDOM.findDOMNode(extraContainerRef.current);
        const router = document.querySelector('.routerWrapper');
        return (
          router.contains(clickedTarget) &&
          !(
            (node && node.contains(clickedTarget)) ||
            (node2 && node2.contains(clickedTarget))
          )
        );
      } else {
        const node = ReactDOM.findDOMNode(containerRef.current);
        const router = document.querySelector('.routerWrapper');
        return (
          node &&
          !node.contains(clickedTarget) &&
          router.contains(clickedTarget)
        );
      }
    }
  },
  groupBy: (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  },
  /**
   *
   * @param {*} list List must have orderHistory property already filled, otherwise this function always returns false.
   * @returns {boolean}
   */
  hasPendingOrder: function (list) {
    const { orderHistory } = list;
    const orderTypes = [
      'company',
      'mailings',
      'phone',
      'name',
      'waystar',
      'hubspot',
    ];
    return orderTypes.some((orderType) => orderHistory?.[orderType]?.isPending);
  },
  hasContractCustomersPermission: function (user) {
    return !!(
      user.info.status & settingsHelper.status.contractCustomersPermission
    );
  },
  hasHubspotIntegrationPermission: function (user) {
    return !!(
      user.info.status & settingsHelper.status.hubspotIntegrationPermission
    );
  },
  isRestricted: function (user) {
    return !!(user.info.access === 'restricted');
  },
  newsTypes: [
    'newsTypeBilprospekt',
    'newsTypeMarket',
    'newsTypeUpdate',
    'newsTypeGuide',
  ],
  openPrint: (title) => {
    let newWindow = window.open('', '_blank', '');
    const content = document.querySelectorAll('#printHidden')[0].innerHTML;

    newWindow.document.open();
    newWindow.document.write(
      '<html><head><title>' +
        title +
        '</title><link rel="stylesheet" type="text/css" href="/print.css" /><link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400&display=swap" rel="stylesheet"></head><body id="printWindow">'
    );
    newWindow.document.write(content);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    newWindow.focus();

    // Timeout is fix for Safari.
    setTimeout(() => {
      newWindow.print();
    }, 100);
  },
  minsToMS(minutes) {
    return minutes * 60 * 1000;
  },
  debounce(func, wait, immediate) {
    let timeout;
    return function (...args) {
      return new Promise((resolve) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          timeout = null;
          if (!immediate) {
            Promise.resolve(func.apply(this, [...args])).then(resolve);
          }
        }, wait);
        if (immediate && !timeout) {
          Promise.resolve(func.apply(this, [...args])).then(resolve);
        }
      });
    };
  },
};
