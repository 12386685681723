import { store } from 'store';
import { previewActionTypes } from './actions';

export const setPreview = (payload) => {
  store.dispatch({ type: previewActionTypes.PREVIEW_SET_DATA, payload: payload.data });
  store.dispatch({ type: previewActionTypes.PREVIEW_SET_ICONS, payload: payload.icons });
  store.dispatch({ type: previewActionTypes.PREVIEW_SET_TYPE, payload: payload.type });
};

export const setPreviewFilterData = (payload) => {
  store.dispatch({ type: previewActionTypes.PREVIEW_SET_FILTER_DATA, payload });
};
