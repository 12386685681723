import MonthlyActivityGoals from './monthly_activity_goals';
/**
 *
 * @param {{height: number;}} props
 * @description In case the user has access to activity goals but no data tied to it, show a default empty chart
 * @returns
 */
export default function MonthlyActivityGoalsFallback({ ...props }) {
  const data = [
    { action: 'call', goal: 0 },
    { action: 'meeting', goal: 0 },
    { action: 'offer', goal: 0 },
  ];
  return <MonthlyActivityGoals {...props} data={data} />;
}
