import ActivityLineChart from 'components/dashboard/charts/activity_line_chart';
import ActivityPieChart from 'components/dashboard/charts/activity_pie_chart';
import styles from './sales.module.css';
import clsx from 'clsx';
import MonthlyActivityGoals from './monthly_activity_goals/monthly_activity_goals';
import ChartContainer from 'components/dashboard/charts/chart_container';
import { useContext, useEffect, useRef, useState } from 'react';
import { request } from 'helpers';
import ActivityList from 'components/dashboard/activity_list/activity_list';
import WeeklyActivities from './weekly_activities/weekly_activities';
import NumberStatistics from 'components/dashboard/number_statistics/number_statistics';
import { useElementSize } from 'hooks/use_element_size';
import CenteredError from 'components/dashboard/centered_error/centered_error';
import { calculateChartSize } from 'components/dashboard/utils';
import LanguageContext from 'language-context';

const BasicSalesDashboard = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const ref = useRef(null);
  const tc = useContext(LanguageContext);
  useEffect(() => {
    async function fetchDashboardData() {
      const result = await request({ method: 'GET', url: '/dashboard/sales' });
      if (!result || result instanceof Error) {
        setData({});
        setError(true);
      } else {
        setData(result);
        setError(null);
      }
    }
    fetchDashboardData();
  }, []);
  const [containerWidth, containerHeight] = useElementSize(ref.current);
  const [chartHeight, chartWidth] = calculateChartSize(
    containerHeight,
    containerWidth
  );

  if (error) {
    return (
      <CenteredError
        errorHeader={tc.tryRefreshing}
        errorText={tc.dashboardFetchFailed}
      />
    );
  }

  return (
    <div ref={ref} className={clsx(showActivities && styles.noscroll)}>
      <NumberStatistics
        data={data?.monthStats}
        onClick={(param) => {
          if (!param) return;
          if (param === 'activityCount') {
            setShowActivities(true);
          }
        }}
      />
      <div className={styles.widgetsWrapper}>
        <ChartContainer className={styles.sparkline}>
          <ActivityLineChart
            activities={data?.activitiesForSparkline || []}
            height={chartHeight}
            width={chartWidth}
          />
        </ChartContainer>
        <ChartContainer className={styles.pie}>
          <ActivityPieChart data={data?.activityCounts || []} />
        </ChartContainer>
        {!data?.activityGoals ? (
          <div></div>
        ) : data.activityGoals.length === 0 ? (
          <div></div>
        ) : (
          <ChartContainer className={styles.goals}>
            <MonthlyActivityGoals
              data={data?.activityGoals}
              height={chartHeight}
              width={chartWidth}
            />
          </ChartContainer>
        )}
        <ChartContainer>
          <WeeklyActivities events={data?.events || []} />
        </ChartContainer>
      </div>
      {showActivities && (
        <ActivityList
          activities={data?.activities || []}
          onClose={() => {
            setShowActivities(false);
          }}
        />
      )}
    </div>
  );
};

export default BasicSalesDashboard;
