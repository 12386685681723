import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './head_of_sales.module.css';
import NumberStatistics from 'components/dashboard/number_statistics/number_statistics';
import ChartContainer from 'components/dashboard/charts/chart_container';
import ActivityLineChart from 'components/dashboard/charts/activity_line_chart';
import { useElementSize } from 'hooks/use_element_size';
import { request } from 'helpers';
import ActivityList from 'components/dashboard/activity_list/activity_list';
import CenteredError from 'components/dashboard/centered_error/centered_error';
import MonthlyActivityGoals from 'components/dashboard/views/sales/monthly_activity_goals/monthly_activity_goals';
import ActivityPieChart from 'components/dashboard/charts/activity_pie_chart';
import FilterMenu from './filter_menu/filter_menu';
import { calculateChartSize } from 'components/dashboard/utils';
import LanguageContext from 'language-context';

const HeadOfSalesDashboard = () => {
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    availableUsers: [],
    availableDealers: [],
  });
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [showActivities, setShowActivities] = useState(false);
  const ref = useRef(null);
  const tc = useContext(LanguageContext);

  function clickUser(userId) {
    setSelectedDealers([]);
    if (selectedUsers.includes(userId)) {
      setSelectedUsers((users) => users.filter((id) => id !== userId));
    } else {
      setSelectedUsers((users) => users.concat(userId));
    }
  }
  function clickDealer(dealerId) {
    setSelectedUsers([]);
    if (selectedDealers.includes(dealerId)) {
      setSelectedDealers((dealers) => dealers.filter((id) => id !== dealerId));
    } else {
      setSelectedDealers((dealers) => dealers.concat(dealerId));
    }
  }

  useEffect(() => {
    async function fetchFilters() {
      try {
        const result = await request({
          method: 'GET',
          url: '/dashboard/filters',
        });
        if (!result || result instanceof Error) {
          setFilters({
            availableUsers: [],
            availableDealers: [],
          });
          setError(true);
        } else {
          setFilters(result);
        }
      } catch (err) {
        setError(true);
        setData({});
      }
    }
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchDashboardData() {
      try {
        const result = await request({
          method: 'POST',
          url: '/dashboard/headOfSales',
          data: {
            dealerIds: selectedDealers,
            userIds: selectedUsers,
          },
        });
        if (!result || result instanceof Error) {
          setData({});
          setError(true);
        } else {
          setData(result);
        }
      } catch (err) {
        setError(true);
        setData({});
      }
    }
    fetchDashboardData();
  }, [selectedDealers, selectedUsers]);

  const [containerWidth, containerHeight] = useElementSize(ref.current);
  const [chartHeight, chartWidth] = calculateChartSize(
    containerHeight,
    containerWidth
  );

  if (error)
    return (
      <CenteredError
        errorText={tc.tryRefreshing}
        errorHeader={tc.dashboardFetchFailed}
      />
    );
  return (
    <div
      ref={ref}
      className={clsx(showActivities && styles.noscroll, styles.container)}
    >
      <FilterMenu
        users={filters?.availableUsers || []}
        selectedUsers={selectedUsers}
        dealers={filters?.availableDealers || []}
        selectedDealers={selectedDealers}
        onClickDealer={clickDealer}
        onClickUser={clickUser}
      />
      <NumberStatistics
        data={data?.monthStats}
        onClick={(param) => {
          if (!param) return;
          if (param === 'activityCount') {
            setShowActivities(true);
          }
        }}
      />
      <div className={styles.widgetsWrapper}>
        <ChartContainer>
          <ActivityLineChart
            activities={data?.activitiesForSparkline || []}
            height={chartHeight}
            width={chartWidth}
          />
        </ChartContainer>
        <ChartContainer>
          <ActivityPieChart data={data?.activityCounts || []} />
        </ChartContainer>
        <ChartContainer>
          <MonthlyActivityGoals
            data={data?.activityGoals}
            height={chartHeight}
            width={chartWidth}
          />
        </ChartContainer>
      </div>
      {showActivities && (
        <ActivityList
          activities={data?.activities || []}
          onClose={() => {
            setShowActivities(false);
          }}
        />
      )}
    </div>
  );
};

export default HeadOfSalesDashboard;
