import React from 'react';
import Icon from 'components/icon';

/**
 * Item for component Dropdown.
 *
 * @param props.active - boolean - Sets active class, and shows check/checkbox.
 * @param props.disabled - boolean
 * @param props.hideCheckbox - boolean (optional) - If we want to hide checkbox.
 * @param props.label - string / element - Usually text to be displayed, but can be JSX element.
 * @param props.onClick - func - Handler for onClick.
 * @param props.onDelete - func - Handler for onDelete.
 */
export const DropdownItemWithDeleteButton = ({
  active,
  disabled,
  label,
  onClick,
  onDelete,
  onEdit,
}) => {
  return (
    <div
      className={`dropdownItemWrapper ${disabled ? 'disabled' : ''} ${
        active ? 'active' : ''
      }`}
      onClick={onClick}
    >
      <div className="dropdownItemWrapper__dropdownItem">
        <div className="dropdownItemWrapper__dropdownItem__label">{label}</div>
        <div className="dropdownItemWrapper__dropdownItem__button">
          <Icon
            val="remove"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
          {onEdit && (
            <Icon
              val="edit"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
