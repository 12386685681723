import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { checkSession, getHelpcrunchUUID } from 'store/user/tasks';
import { LanguageProvider } from 'language-context';
import * as text from 'text-content';
import history from 'router-history';
import Router from 'components/router';
import './styles/main.sass';
import './styles/global_colors.css';
import VersionHandler from 'components/version_error/version_handler';

/**
 * Root component.
 */
const AppHolder = (state) => {
  const [lang, setLang] = useState(text.swedish);
  const type = useMemo(() => {
    return state.user.clientIsOutOfDate;
  }, [state.user.clientIsOutOfDate]);

  useEffect(() => {
    // Set which language file to be provided via context.
    if (state.user?.info?.lang) {
      switch (state.user.info.lang) {
        case 'en':
          setLang(text.english);
          break;
        case 'swe':
          setLang(text.swedish);
          break;
        default:
          setLang(text.swedish);
      }
    }
  }, [state.user]);

  useEffect(() => {
    const _checkSession = () => {
      if (state.user?.info?.id && state.user.info.hasOwnProperty('gdpr')) {
        if (state.user.info.gdpr !== 1) {
          // If user haven't approved gdpr policy, redirect.
          if (window.location.pathname !== '/informationssakerhet') {
            // Since we use history.listen it will create infinite loop if pushing to history when already on correct page.
            return history.push('/informationssakerhet');
          }
        }
      } else {
        checkSession();
      }
    };

    _checkSession(); // Run this check every time user object change
    return history.listen(() => {
      // Also run it when page change.
      checkSession();
    });
  }, [state.user.info]);

  useEffect(() => {
    const _adjustHelpcrunchUserData = async () => {
      if (!window.HelpCrunch) {
        return;
      }
      // HelpCrunch chat is added in index.html via script tag. Here we add/remove user data to it.
      if (state.user?.info?.id) {
        const uuid = await getHelpcrunchUUID();

        if (!uuid) {
          return window.HelpCrunch('logout', function () {});
        }

        const user = {
          email: state.user.info.email,
          name: state.user.info.name,
          company: state.user.info.dealerName,
          phone: state.user.info.phone ? state.user.info.phone.toString() : '', // String
          security_hash: uuid,
          user_id: state.user.info.id.toString(), // String
        };

        window.HelpCrunch('updateUser', user, '', function () {});
      } else {
        window.HelpCrunch('logout', function () {});
      }
    };

    _adjustHelpcrunchUserData();
  }, [state.user.info]);

  return (
    <LanguageProvider value={lang}>
      <Router />
      <VersionHandler type={type} />
    </LanguageProvider>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(AppHolder);
