import styles from './version_error.module.css';
import Logo from '../../images/bilprospekt_logo_blue.png';
import ActionButton from 'components/action_button';
import { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import Popup from 'components/popup';
import { miscHelper } from 'helpers';
import Icon from 'components/icon';

/**
 *
 * @param {{type: 'major'|'minor'|'bugfix'}} props.type
 * @returns
 */
export default function VersionError({ type }) {
  const [show, setShow] = useState(true);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (!type) return;
    if (type === 'bugfix') return;
    const interval = setInterval(() => {
      setShow(true);
    }, miscHelper.minsToMS(30));
    return () => clearInterval(interval);
  }, [show, type]);
  const content = (
    <div className={styles.version_error_container}>
      <button
        className={styles.version_error_close}
        disabled={type !== 'minor'}
        onClick={() => {
          if (type !== 'minor') return;
          setShow(false);
        }}
      >
        <Icon val="clear" />
      </button>

      <img
        src={Logo}
        alt="Bilprospekt logo"
        className={styles.version_error_img}
      />
      <p>{tc.clientVersionOutdatedInfo}</p>
      <ActionButton
        onClick={() => window.location.reload()}
        type="highlight"
        label={tc.reload}
      />
    </div>
  );
  if (!show) return null;
  if (type === 'bugfix') return null; // no popup/notification on hotfix version release
  if (type === 'minor') {
    return (
      <Popup
        close={() => {
          setShow(false); // allowed to hide popup for 30 or 60 minutes on minor version release
        }}
      >
        {content}
      </Popup>
    );
  }
  if (type === 'major') {
    return (
      <Popup
        close={() => {
          return; // no closing the popup on major version release
        }}
      >
        {content}
      </Popup>
    );
  }
  return null;
}
