import Icon from 'components/icon';

/**
 * Render an info component.
 * Normally expects a <h4> element and optional <p> elements to follow as props.children.
 *
 * @param props.inline - bool (optional) - When we don't want to display a smaller version.
 */
const Info = (props) => {
  return (
    <div className={`infoWrapper ${props.inline ? 'inline' : ''}`}>
      <div className="infoWrapper__info">
        <div className="infoWrapper__info__left">
          <Icon val="infoOutlined" />
        </div>
        <div className="infoWrapper__info__right">{props.children}</div>
      </div>
    </div>
  );
};

export default Info;
