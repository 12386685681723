import { Dropdown, DropdownItem } from 'components/dropdown';
import styles from './dealer_menu.module.css';
import { useContext } from 'react';
import LanguageContext from 'language-context';

export default function DealerMenu({ dealers, selectedDealers, onClick }) {
  const tc = useContext(LanguageContext);
  return (
    <div>
      <Dropdown displayValue={tc.chooseDealer} transparent={true}>
        {dealers.map((dealer) => {
          return (
            <DropdownItem
              key={dealer.id}
              label={dealer.name}
              onClick={() => onClick(dealer.id)}
              active={selectedDealers.includes(dealer.id)}
            />
          );
        })}
      </Dropdown>
    </div>
  );
}
