import { activityHelper } from 'helpers';
import LanguageContext from 'language-context';
import { useContext } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import PieChartCustomLabel from './pie_chart_custom_label';

export default function ActivityPieChart({ height, data = [] }) {
  const tc = useContext(LanguageContext);
  const dataFixed =
    data.length > 0
      ? data.map((obj) => {
          return { ...obj, name: toLabel(obj.action) };
        })
      : [{ count: 1, name: toLabel('no_activity') }];

  function toLabel(action) {
    if (action === 'no_activity') return 'Ingen aktivitet under månaden';
    return activityHelper.getReadableActivity(action);
  }

  const COLORS = [
    '#ffb700',
    '#1818f0',
    '#ff8119',
    '#bf00cb',
    '#ff3246',
    '#ff009d',
    '#ff006f',
    '#ffe51c',
  ];

  return (
    <div>
      {data.length === 0 && <p>{tc.activityMissingThisMonth}</p>}
      {data.length && <h4>{tc.activityMonthly}</h4>}
      <PieChart
        width={350}
        height={250}
        data={dataFixed}
        margin={{ top: 0, left: -5 }}
      >
        <Pie
          data={dataFixed}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({ ...props }) => <PieChartCustomLabel {...props} />}
          outerRadius={80}
          innerRadius={60}
          paddingAngle={3}
          dataKey="count"
        >
          {data.map((_entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            );
          })}
        </Pie>
        <Tooltip
          labelFormatter={(_label, _payload) => {
            return '';
          }}
        />

        <Legend
          formatter={(value) => String(value).slice(0, 10)}
          align="left"
          verticalAlign="middle"
          layout="vertical"
        />
      </PieChart>
    </div>
  );
}
