import clsx from 'clsx';
import styles from './checkbox.module.css';

function Checkbox({ label, checked, onChange, disabled = false }) {
  return (
    <label className={clsx(styles.label, disabled && styles.disabled)}>
      {label}
      <input
        className={styles.input}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </label>
  );
}

export default Checkbox;
