import { miscHelper } from 'helpers';

export const getMenuItems = ({ user, tc }) => {
  //   const dashboardChildren = [
  //     {
  //       icon: 'dashboard',
  //       name: tc.dashboard,
  //       path: '/',
  //       id: 'dashboard',
  //     },
  //     {
  //       icon: 'newsItem',
  //       name: tc.news,
  //       path: '/nyheter',
  //       id: 'news',
  //     },
  //   ];
  const isRestricted = miscHelper.isRestricted(user);

  const listsBaseChildren = [
    { icon: 'lists', name: tc.yourLists, path: '/listor', id: 'lists' },
    {
      icon: 'archive',
      name: tc.archivedLists,
      path: '/listor/arkiverade',
      id: 'archivedLists',
    },
  ];

  const listsRestrictedChildren = [
    {
      icon: 'orders',
      name: tc.toOrder,
      path: '/listor/bestalla',
      id: 'orders',
    },
    {
      icon: 'subscription',
      name: tc.listsSubscriptions,
      path: '/listor/prenumerationer',
      id: 'subscriptions',
    },
    {
      icon: 'subscription',
      name: tc.subscriptionLists,
      path: '/listor/prenumerationslistor',
      id: 'subscriptionLists',
    },
  ];
  const items = [
    // {
    //   icon: 'home',
    //   name: tc.start,
    //   //   path: '/',
    //   id: 'start',
    //   children: dashboardChildren,
    // },
    {
      icon: 'dashboard',
      name: tc.dashboard,
      path: '/',
      id: 'dashboard',
    },
    {
      icon: 'newsItem',
      name: tc.news,
      path: '/nyheter',
      id: 'news',
    },
    {
      icon: 'agile',
      name: tc.pipe,
      path: '/bearbeta',
      id: 'agileBoard',
    },
    // {
    //   icon: 'measureActivity',
    //   name: tc.activity,
    //   path: '/bearbeta/aktivitet',
    //   id: 'activity',
    // },
    // {
    //   icon: 'agile',
    //   name: tc.agile,
    //   id: 'agile',
    //   children: [
    //     {
    //       icon: 'agile',
    //       name: tc.pipe,
    //       path: '/bearbeta',
    //       id: 'agileBoard',
    //     },
    //     {
    //       icon: 'measureActivity',
    //       name: tc.activity,
    //       path: '/bearbeta/aktivitet',
    //       id: 'activity',
    //     },
    //   ],
    // },
    {
      icon: 'lists',
      name: tc.lists,
      //   path: '/listor',
      id: 'lists',
      children: miscHelper.hasContractCustomersPermission(user)
        ? listsBaseChildren
            .concat({
              icon: 'lists',
              name: tc.contractCustomerLists,
              path: '/listor/avtal',
              id: 'contractCustomerLists',
            })
            .concat(isRestricted ? [] : listsRestrictedChildren)
        : listsBaseChildren.concat(isRestricted ? [] : listsRestrictedChildren),
    },
    {
      icon: 'prospectSearch',
      name: tc.prospectSearch,
      path: '/prospektera',
      id: 'prospect',
    },
    {
      icon: 'analysis',
      name: tc.analyse,
      path: '/analysera',
      id: 'analysis',
    },
    { icon: 'leads', name: tc.leads, path: '/leads', id: 'leads' },
    {
      icon: 'analytics',
      path: '/reports',
      name: tc.yourReports,
      id: 'reports',
    },
    {
      icon: 'download',
      name: tc.exports,
      path: '/reports/exports',
      id: 'exports',
    },
    // {
    //   icon: 'leads',
    //   path: '/reports',
    //   name: tc.reports,
    //   id: 'reports',
    //   children: [
    //     {
    //       icon: 'leads',
    //       path: '/reports',
    //       name: tc.yourReports,
    //       id: 'reports',
    //     },
    //     {
    //       icon: 'leads',
    //       name: tc.exports,
    //       path: '/reports/exports',
    //       id: 'exports',
    //     },
    //   ],
    // },
  ];
  return items;
};
